import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import GroupProductService from 'services/GroupProductService';
import Utils from 'utils';
import openNotification from 'utils/notification';

export const getGroupProductApi = createAsyncThunk(
  'groupProduct/getGroupProductApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await GroupProductService.groupProductSearchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getGroupProductById = createAsyncThunk(
  'groupProduct/getGroupProductById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await GroupProductService.getGroupProductById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const addGroupProductApi = createAsyncThunk(
  'groupProduct/addGroupProductApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await GroupProductService.addGroupProduct(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const updateGroupProductApi = createAsyncThunk(
  'groupProduct/updateGroupProductApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await GroupProductService.updateGroupProduct(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const delGroupProductApi = createAsyncThunk(
  'groupProduct/delGroupProductApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await GroupProductService.delGroupProduct(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const importExcelApi = createAsyncThunk(
  'groupProduct/importExcelApi',
  async (model, { rejectWithValue }) => {
    try {
      const { onSuccess } = model;
      const response = await GroupProductService.importExcel(model);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  groupProductList: [],
  groupProductDetail: {},
};

export const groupProductSlice = createSlice({
  name: 'groupProduct',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setGroupProductDetail: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroupProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGroupProductApi.fulfilled, (state, action) => {
        state.loading = false;
        state.groupProductList = action.payload;
      })
      .addCase(getGroupProductApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getGroupProductById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGroupProductById.fulfilled, (state, action) => {
        state.loading = false;
        state.groupProductDetail = action.payload;
      })
      .addCase(getGroupProductById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addGroupProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addGroupProductApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addGroupProductApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateGroupProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGroupProductApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateGroupProductApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(delGroupProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delGroupProductApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(delGroupProductApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(importExcelApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(importExcelApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(importExcelApi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setGroupProductDetail } = groupProductSlice.actions;

export default groupProductSlice.reducer;
