import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import WarrantyHistoryService from 'services/WarrantyHistoryService';
import Utils from 'utils';
import openNotification from 'utils/notification';

export const getWarrantyHistoryApi = createAsyncThunk(
  'warrantyHistory/getWarrantyHistoryApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await WarrantyHistoryService.warrantyHistorySearchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getWarrantyHistoryByProductApi = createAsyncThunk(
  'warrantyHistory/getWarrantyHistoryByProductApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await WarrantyHistoryService.getWarrantyHistoryByProduct(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getWarrantyHistoryById = createAsyncThunk(
  'warrantyHistory/getWarrantyHistoryById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await WarrantyHistoryService.getWarrantyHistoryById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const addWarrantyHistoryApi = createAsyncThunk(
  'warrantyHistory/addWarrantyHistoryApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await WarrantyHistoryService.addWarrantyHistory(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const updateWarrantyHistoryApi = createAsyncThunk(
  'warrantyHistory/updateWarrantyHistoryApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await WarrantyHistoryService.updateWarrantyHistory(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const delWarrantyHistoryApi = createAsyncThunk(
  'warrantyHistory/delWarrantyHistoryApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await WarrantyHistoryService.delWarrantyHistory(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const warrantyHistoryExportExcelApi = createAsyncThunk(
  'warrantyHistory/warrantyHistoryExportExcelApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await WarrantyHistoryService.warrantyHistoryExportExcel(data);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  warrantyHistoryList: [],
  warrantyHistoryByProductList: [],
  warrantyHistoryDetail: {},
  totalItems: 0,
};
export const warrantyHistorySlice = createSlice({
  name: 'warrantyHistory',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setWarrantyHistoryDetail: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWarrantyHistoryApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWarrantyHistoryApi.fulfilled, (state, action) => {
        state.loading = false;
        state.warrantyHistoryList = action.payload;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getWarrantyHistoryApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getWarrantyHistoryByProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWarrantyHistoryByProductApi.fulfilled, (state, action) => {
        state.loading = false;
        state.warrantyHistoryByProductList = action.payload;
      })
      .addCase(getWarrantyHistoryByProductApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getWarrantyHistoryById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWarrantyHistoryById.fulfilled, (state, action) => {
        state.loading = false;
        state.warrantyHistoryDetail = action.payload;
      })
      .addCase(getWarrantyHistoryById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addWarrantyHistoryApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addWarrantyHistoryApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addWarrantyHistoryApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateWarrantyHistoryApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateWarrantyHistoryApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateWarrantyHistoryApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(delWarrantyHistoryApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delWarrantyHistoryApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(delWarrantyHistoryApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(warrantyHistoryExportExcelApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(warrantyHistoryExportExcelApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(warrantyHistoryExportExcelApi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setWarrantyHistoryDetail } = warrantyHistorySlice.actions;

export default warrantyHistorySlice.reducer;
