// @ts-check
/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react,react/prop-types */
import { Button, Result } from 'antd';
import { useNavigate, useRouteError } from 'react-router-dom';
import { ReactComponent as FindNotFound } from 'assets/svg/find-notfound.svg';
import { ProductNotFoundError } from './shared';

export function ErrorBoundary() {
  const error = /** @type {any} */ (useRouteError());
  const navigate = useNavigate();

  /** @type {import('antd/lib/result').ResultStatusType} */
  let status = 500;
  let subTitle = 'Xin lỗi, đã có lỗi xảy ra';
  /** @type {import('antd').ResultProps['icon']} */
  let icon;
  if (error instanceof ProductNotFoundError) {
    status = 404;
    subTitle =
      'Số điện thoại này chưa đăng ký sản phẩm nào. Vui lòng dùng camera và quét QRcode trên sản phẩm hoặc nhập Serial Number/Mã kích hoạt và thực hiện tìm kiếm';
    icon = <FindNotFound />;
  }

  return (
    <Result
      style={{ borderTop: '1px solid var(--gray-3)' }}
      title={status}
      subTitle={subTitle}
      icon={icon}
      extra={
        <Button type='primary' onClick={() => navigate('/')}>
          Trở về trang tìm kiếm
        </Button>
      }
    />
  );
}
