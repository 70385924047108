import axiosInstance from 'auth/FetchInterceptor';
import {
  PRODUCER_API,
  PRODUCER_SEARCH_GRID,
  PRODUCER_CREATE,
  PRODUCER_UPDATE,
  PRODUCER_IMPORT_EXCEL,
} from 'constants/ApiConstant';

const ProducerService = {};

ProducerService.producerSearchGrid = (data) => {
  return axiosInstance.post(PRODUCER_SEARCH_GRID, data);
};

ProducerService.getProducerById = (id) => {
  return axiosInstance.get(`${PRODUCER_API}/${id}`, null);
};

ProducerService.addProducer = (data) => {
  return axiosInstance.post(PRODUCER_CREATE, data);
};

ProducerService.updateProducer = (data) => {
  return axiosInstance.put(PRODUCER_UPDATE, data);
};

ProducerService.delProducer = (id) => {
  return axiosInstance.delete(`${PRODUCER_API}/${id}`, null);
};

ProducerService.importExcel = function (model) {
  const formData = new FormData();
  formData.append('file', model.file);

  return axiosInstance.post(PRODUCER_IMPORT_EXCEL, formData, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export default ProducerService;
