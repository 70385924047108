export const AUTH_TOKEN = 'auth_token';
export const REFRESH_TOKEN = 'refresh_token';
export const EXPIRE_TIME = 'expire_time';
export const FIREBASE_TOKEN = 'firebase_token';
export const LANGUAGE_LOCAL = 'language_local';
export const USER_ROLES = 'user_roles';

export const SYSTEM_ROLES = {
  None: 0,
  // Operation system 0x
  OperatorAdmin: 1,
  // Partner role 1X
  OperatorAgency: 2,
  OperatorAccountant: 3,
  Driver: 10,
  // EndUser 2X
  EndUser: 20,
};
