// @ts-check
/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react,react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Image, Modal, Result } from 'antd';
import { QRCodeSVG } from 'qrcode.react';
import moment from 'moment';

import fallback from 'assets/images/image-fallback.png';
import { WarrantyActiveStatus } from 'services/WarrantyService';
import { StatusButton } from '../shared/StatusButton';
import RegisterProductModal from './RegisterProductModal/RegisterProductModal';

import style from './Overview.module.css';

/** @typedef {import('./RegisterProductModal/RegistrationForm').UploadItem} UploadItem */

/**
 * @param {Object} props
 * @param {import('services/WarrantyService').ProductWarranty} props.data
 */
export function Overview({ data }) {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [oldStatus, setOldStatus] = useState(data.status);

  useEffect(() => {
    if (data.status !== oldStatus && data.status === WarrantyActiveStatus.Active) {
      setIsSuccessOpen(true);
    }
    setOldStatus(data.status);
  }, [data]);

  return (
    <section className={style.overview}>
      <Image src={data.imageUrl ?? fallback} fallback={fallback} rootClassName={style.image} />
      <h3>{data.groupName}</h3>
      <div className={style.fields}>
        <div className={style.fieldWrapper}>
          <span className={style.label}>Serial number: </span>
          <span className={style.field}>{data.serialNumber}</span>
        </div>
        <div className={style.fieldWrapper}>
          <span className={style.label}>Nhóm sản phẩm: </span>
          <span className={style.field}>{data.categoryName}</span>
        </div>
      </div>
      <QRCodeSVG
        value={window.location.origin + window.location.pathname}
        bgColor='transparent'
        className={style.qr}
      />
      <span className={style.activationCode}>{data.code}</span>
      <StatusButton
        status={data.status}
        onClick={() => setIsFormOpen(true)}
        className={style.btn}
      />
      <RegisterProductModal
        data={data}
        isOpen={!isSuccessOpen && isFormOpen}
        setIsOpen={setIsFormOpen}
      />
      <Modal
        open={isSuccessOpen}
        onOk={() => setIsSuccessOpen(false)}
        closable={false}
        footer={null}
      >
        <Result
          status='success'
          title='Đăng ký bảo hành thành công !'
          subTitle={
            <>
              Sản phẩm của Quý khách đã đăng ký bảo hành thành công.
              <br />
              Cảm ơn đã tin tưởng và lựa chọn sản phẩm chính hãng của AGURI.
              <br />
              <br />
              Thời hạn bảo hành sản phẩm đến hết ngày:{' '}
              <span className={style.resultEndDate}>
                {moment().add(data.daysLeft, 'days').format('DD/MM/YYYY')}
              </span>
            </>
          }
          extra={
            <Button type='primary' htmlType='button' onClick={() => setIsSuccessOpen(false)}>
              Tôi đã hiểu
            </Button>
          }
        />
      </Modal>
    </section>
  );
}
