/* eslint-disable react/react-in-jsx-scope */
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { warrantyRoutes } from 'warrantyRoutes';
import store from './store';
import Layouts from './layouts';
import { THEME_CONFIG } from './configs/AppConfig';
import './lang';
import { WEB_WARRANTY_PAGE } from './constanst';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function Root() {
  const { i18n } = useTranslation();

  return (
    <>
      <Helmet defaultTitle='Aguri' htmlAttributes={{ lang: i18n.language }}>
        <meta name='description' content='A Aguri Application' />
      </Helmet>
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={THEME_CONFIG.currentTheme}
        insertionPoint='styles-insertion-point'
      >
        <Layouts />
      </ThemeSwitcherProvider>
    </>
  );
}

const routerApp = () => {
  return createBrowserRouter(
    process.env.REACT_APP_WEB_PAGE === WEB_WARRANTY_PAGE
      ? warrantyRoutes
      : [{ path: '*', Component: Root }],
  );
};

function App() {
  return (
    <div className='App'>
      <Provider store={store}>
        <HelmetProvider>
          <RouterProvider router={routerApp()} />
        </HelmetProvider>
      </Provider>
    </div>
  );
}

export default App;
