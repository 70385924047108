/* eslint-disable max-classes-per-file */
// @ts-check
/** @typedef {import('antd/es/modal/confirm').ModalStaticFunctions} ModalStaticFunctions */

import { createContext, useContext } from 'react';

export const ModalContext = createContext(
  /** @type {Omit<ModalStaticFunctions, "warn"> | null} */ (null),
);

export function useModal() {
  const modalApi = useContext(ModalContext);

  if (!modalApi) {
    throw new Error(`${useModal.name} has to be used within <ModalContext.Provider>`);
  }

  return modalApi;
}

export const MessageContext = createContext(
  /** @type {import('antd/lib/message').MessageInstance | null} */ (null),
);

export function useMessage() {
  const messageApi = useContext(MessageContext);
  if (!messageApi) {
    throw new Error(`${useMessage.name} has to be used within <MessageContext.Provider>`);
  }
  return messageApi;
}

export class NetworkError extends Error {
  constructor() {
    super();
    this.name = this.constructor.name;
  }
}

export class ProductNotFoundError extends Error {
  /** @param {string} [q] */
  constructor(q) {
    super();
    this.name = this.constructor.name;
    this.q = q;
  }
}
