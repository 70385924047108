import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash';
import Utils from 'utils';
import openNotification from 'utils/notification';
import EmployeeService from 'services/EmployeeService';

export const getEmployeeApi = createAsyncThunk(
  'employee/getEmployee',
  async (data, { rejectWithValue }) => {
    try {
      const response = await EmployeeService.getEmployee(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getAllEmployeeApi = createAsyncThunk(
  'employee/getAllEmployee',
  async (data, { rejectWithValue }) => {
    try {
      const response = await EmployeeService.getAllEmployee(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getEmployeeById = createAsyncThunk(
  'employee/getEmployeeById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await EmployeeService.getEmployeeById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const createEmployeeApi = createAsyncThunk(
  'employee/createEmployee',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await EmployeeService.createEmployee(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const updateEmployeeApi = createAsyncThunk(
  'employee/updateEmployee',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await EmployeeService.updateEmployee(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const delEmployeeApi = createAsyncThunk(
  'employee/delEmployeeApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await EmployeeService.delEmployee(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const changeStatusEmployee = createAsyncThunk(
  'employee/changeStatusEmployee',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const response = await EmployeeService.changeStatusEmployee(data);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const changePasswordEmployee = createAsyncThunk(
  'employee/changePasswordEmployee',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await EmployeeService.changePasswordEmployee(data);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);
const initialState = {
  loading: false,
  employeeList: [],
  employeeAllList: [],
  employeeDetail: {},
};
export const storeEmployeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setEmployee: (state, action) => {
      state.employeeList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployeeApi.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeList = action.payload;
      })
      .addCase(getEmployeeApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllEmployeeApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEmployeeApi.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeAllList = action.payload;
      })
      .addCase(getAllEmployeeApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getEmployeeById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployeeById.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeDetail = action.payload;
      })
      .addCase(getEmployeeById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createEmployeeApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEmployeeApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createEmployeeApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateEmployeeApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateEmployeeApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateEmployeeApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(delEmployeeApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delEmployeeApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(delEmployeeApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changeStatusEmployee.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeStatusEmployee.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changeStatusEmployee.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changePasswordEmployee.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePasswordEmployee.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changePasswordEmployee.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setEmployee } = storeEmployeeSlice.actions;

export default storeEmployeeSlice.reducer;
