import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { onBlankLayout } from 'store/slices/themeSlice';

// TODO will be replaced later: define props type.
// eslint-disable-next-line react/prop-types
function AppRoute({ component: Component, title, routeKey, blankLayout, ...props }) {
  const dispatch = useDispatch();
  useEffect(() => {
    const isBlank = !!blankLayout;
    dispatch(onBlankLayout(isBlank));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blankLayout]);

  return (
    <>
      <Helmet title={title} />
      <Component {...props} />
    </>
  );
}

export default AppRoute;
