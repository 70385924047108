import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SubUserService from 'services/SubUserService';

export const getSubUserApi = createAsyncThunk(
  'subUser/getSubUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SubUserService.getSubUser(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getSubUserById = createAsyncThunk(
  'subUser/getSubUserById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await SubUserService.getSubUserById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  subUserList: [],
  subUserDetail: {},
};
export const storeSubUserSlice = createSlice({
  name: 'subUser',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setSubUser: (state, action) => {
      state.subUserList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubUserApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubUserApi.fulfilled, (state, action) => {
        state.loading = false;
        state.subUserList = action.payload;
      })
      .addCase(getSubUserApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSubUserById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubUserById.fulfilled, (state, action) => {
        state.loading = false;
        state.subUserDetail = action.payload;
      })
      .addCase(getSubUserById.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setSubUser } = storeSubUserSlice.actions;

export default storeSubUserSlice.reducer;
