import {
  REPORT_GET_IMPORTANT,
  REPORT_GET_IMPORTANT_CHART,
  REPORT_GET_IMPORTANT_WARRANTY,
  REPORT_GET_IMPORTANT_WARRANTY_CHART,
  REPORT_GET_IMPORTANT_WARRANTY_CHART_TIME,
} from 'constants/ApiConstant';
import axiosInstance from '../auth/FetchInterceptor';

const ReportService = {};

ReportService.getImportantIndex = function (data) {
  return axiosInstance.post(REPORT_GET_IMPORTANT, data);
};

ReportService.getImportantChart = function (data) {
  return axiosInstance.post(REPORT_GET_IMPORTANT_CHART, data);
};

ReportService.getImportantWarranty = function () {
  return axiosInstance.get(REPORT_GET_IMPORTANT_WARRANTY);
};

ReportService.getImportantWarrantyChart = function () {
  return axiosInstance.get(REPORT_GET_IMPORTANT_WARRANTY_CHART);
};

ReportService.getImportantWarrantyChartTime = function (data) {
  return axiosInstance.post(REPORT_GET_IMPORTANT_WARRANTY_CHART_TIME, data);
};

export default ReportService;
