import axiosInstance from 'auth/FetchInterceptor';
import {
  EMPLOYEE_API,
  EMPLOYEE_CHANGE_STATUS,
  EMPLOYEE_CREATE,
  EMPLOYEE_UPDATE,
  SEARCH_EMPLOYEE,
  EMPLOYEE_CHANGE_PASSWORD,
  ALL_EMPLOYEE,
} from 'constants/ApiConstant';

const EmployeeService = {};

EmployeeService.getEmployee = function (data) {
  return axiosInstance.post(SEARCH_EMPLOYEE, data);
};
EmployeeService.getAllEmployee = function () {
  return axiosInstance.get(ALL_EMPLOYEE);
};
EmployeeService.getEmployeeById = function (id) {
  return axiosInstance.get(`${EMPLOYEE_API}/${id}`, null);
};
EmployeeService.createEmployee = function (data) {
  return axiosInstance.post(EMPLOYEE_CREATE, data);
};
EmployeeService.updateEmployee = function (data) {
  return axiosInstance.put(EMPLOYEE_UPDATE, data);
};
EmployeeService.changeStatusEmployee = function (data) {
  return axiosInstance.put(EMPLOYEE_CHANGE_STATUS, data);
};
EmployeeService.changePasswordEmployee = function (data) {
  return axiosInstance.put(EMPLOYEE_CHANGE_PASSWORD, data);
};
EmployeeService.delEmployee = function (id) {
  return axiosInstance.delete(`${EMPLOYEE_API}/id?id=${id}`, null);
};

export default EmployeeService;
