export const API_BASE_URL = 'https://your-api-url.com/';
const PREFIX = '/vi/api/v1.0';
const PREFIX_V2 = '/vi/api/v2.0';

export const GET_PROFILE = `${PREFIX}/User/profile`;
export const ACCOUNT_LOGIN = `${PREFIX}/User/login`;
export const SIGN_OUT = `${PREFIX}/User/logout`;
export const FORGOT_PASSWORD = `${PREFIX}/User/password/forgot-by-email`;
export const RESET_PASSWORD = `${PREFIX}/User/password/reset-by-email`;
export const SEARCH_BY_ROLES = `${PREFIX}/User/search-by-roles`;
export const REFRESH_TOKEN_API = `${PREFIX}/TokenManager/refresh-token`;
export const UPDATE_PROFILE = `${PREFIX}/User/profile`;
export const CHANGE_PASS_PROFILE = `${PREFIX}/User/change-pass-profile`;

export const PARTNER_API = `${PREFIX}/Partner`;
export const PARTNER_SEARCH_GRID = `${PARTNER_API}/search-grid`;
export const PARTNER_CHANGE_STATUS_API = `${PARTNER_API}/change-status`;
export const PARTNER_REGISTER_API = `${PARTNER_API}/register`;
export const PARTNER_REGISTER_VERIFY_API = `${PARTNER_API}/register/verify`;
export const PARTNER_CHANGE_PRESTIGE = `${PARTNER_API}/change-prestige`;

const LOCATION_API = `${PREFIX}/Location`;
export const PROVINCE_BY_COUNTRY = `${LOCATION_API}/province-by-country`;
export const DISTRICT_BY_PROVINCE = `${LOCATION_API}/district-by-province`;
export const WARD_BY_DISTRICT = `${LOCATION_API}/ward-by-district`;

const NOTIFICATION_API = `${PREFIX}/Notification`;
export const SEARCH_NOTIFICATION = `${NOTIFICATION_API}/search`;
export const READ_ALL_NOTIFICATION = `${NOTIFICATION_API}/read-all`;
export const DEL_ALL_NOTIFICATION = `${NOTIFICATION_API}/delete-all`;
export const SAVE_FIREBASE_TOKEN_NOTIFICATION = `${NOTIFICATION_API}/save-firebase-token`;
export const READ_NOTIFICATION = `${NOTIFICATION_API}/mark-read`;
export const SAVE_LABEL_STAR_NOTIFICATION = `${NOTIFICATION_API}/save-label-star`;
export const DEL_MULTI_NOTIFICATION = `${NOTIFICATION_API}/delete-multi`;

export const WARNING_API = `${PREFIX}/Warning`;
export const WARNING_SEARCH_GRID = `${WARNING_API}/search-grid`;
export const HANDLE_WARNING = `${WARNING_API}/warning-handle`;

export const SEARCH_WARNING_DETAIL = `${WARNING_API}/detail`;
export const MAP_SEARCH_API = `${PREFIX}/Monitor/search`;
export const MAP_WORKINGAREA_API = `${PREFIX}/Monitor/working-areas`;
export const SEARCH_PLACE_API = `${PREFIX}/Monitor/search-place`;
export const GET_PLACE_DETAIL_API = `${PREFIX}/Monitor/place-detail`;
export const GET_DISTANCE_MATRIX = `${PREFIX}/Monitor/get-distance-matrix`;

export const CUSTOMER_API = `${PREFIX}/User`;
export const CUSTOMER_CHANGE_STATUS = `${CUSTOMER_API}/change-status`;
export const SEARCH_CUSTOMER = `${CUSTOMER_API}/search-grid-customer`;
export const CUSTOMER_EXPORT_EXCEL = `${CUSTOMER_API}/export-excel`;
export const ALL_USER = `${CUSTOMER_API}/get-all-user`;

export const SUB_USER_API = `${PREFIX}/SubUser`;
export const LIST_SUB_USER = `${SUB_USER_API}/search-grid-sub-user`;

export const HISTORY_MEASURES_API = `${PREFIX}/HistoryMeasures`;
export const LIST_HISTORY_MEASURES = `${HISTORY_MEASURES_API}/history-by-range-date`;
export const HISTORY_MEASURES = `${PREFIX_V2}/HistoryMeasures/info-compare-recently`;

export const EMPLOYEE_API = `${PREFIX}/User`;
export const EMPLOYEE_CREATE = `${EMPLOYEE_API}/create-user`;
export const EMPLOYEE_UPDATE = `${EMPLOYEE_API}/update-user`;
export const EMPLOYEE_CHANGE_STATUS = `${EMPLOYEE_API}/change-status`;
export const EMPLOYEE_CHANGE_PASSWORD = `${EMPLOYEE_API}/change-pass-employee`;
export const SEARCH_EMPLOYEE = `${EMPLOYEE_API}/search-grid-employee`;
export const ALL_EMPLOYEE = `${EMPLOYEE_API}/get-all-employee`;

export const PRODUCT_API = `${PREFIX}/Product`;
export const PRODUCT_SEARCH_GRID = `${PRODUCT_API}/search-grid`;
export const PRODUCT_GET_ALL = `${PRODUCT_API}/get-all`;
export const PRODUCT_CREATE = `${PRODUCT_API}/create-product`;
export const PRODUCT_UPDATE = `${PRODUCT_API}/update-product`;
export const PRODUCTS_GROUP_CATEGORY = `${PRODUCT_API}/group-category`;
export const PRODUCTS_CHANGE_STATUS = `${PRODUCT_API}/change-status`;
export const PRODUCT_DETAIL_API = `${PRODUCT_API}/detail`;

export const PRODUCT_TRANSLATION_API = `${PREFIX}/ProductTranslation`;
export const PRODUCT_TRANSLATION_CREATE = `${PRODUCT_TRANSLATION_API}/create-product-translation`;
export const PRODUCT_TRANSLATION_UPDATE = `${PRODUCT_TRANSLATION_API}/update-product-translation`;

export const NEWS_TRANSLATION_API = `${PREFIX}/NewsTranslation`;
export const NEWS_TRANSLATION_CREATE = `${NEWS_TRANSLATION_API}/create-news-translation`;
export const NEWS_TRANSLATION_UPDATE = `${NEWS_TRANSLATION_API}/update-news-translation`;

export const PRODUCER_API = `${PREFIX}/Producer`;
export const PRODUCER_SEARCH_GRID = `${PRODUCER_API}/search-grid`;
export const PRODUCER_CREATE = `${PRODUCER_API}/create-producer`;
export const PRODUCER_UPDATE = `${PRODUCER_API}/update-producer`;
export const PRODUCER_IMPORT_EXCEL = `${PRODUCER_API}/import-excel`;

export const GROUP_PRODUCT_API = `${PREFIX}/GroupProduct`;
export const GROUP_PRODUCT_SEARCH_GRID = `${GROUP_PRODUCT_API}/search-grid`;
export const GROUP_PRODUCT_CREATE = `${GROUP_PRODUCT_API}/create-group-product`;
export const GROUP_PRODUCT_UPDATE = `${GROUP_PRODUCT_API}/update-group-product`;
export const GROUP_PRODUCT_IMPORT_EXCEL = `${GROUP_PRODUCT_API}/import-excel`;

export const DEVICE_API = `${PREFIX}/Device`;
export const DEVICE_SEARCH_GRID = `${DEVICE_API}/search-grid`;
export const CONNECTION_HISTORY = `${DEVICE_API}/connection-history`;
export const DEVICE_EXPORT_EXCEL = `${DEVICE_API}/export-excel`;
export const DEVICE_IMPORT_EXCEL = `${DEVICE_API}/import-excel`;
export const DEVICE_UPDATE = `${DEVICE_API}/update-device`;

export const PRODUCT_WARRANTY_API = `${PREFIX}/ProductWarranty`;
export const PRODUCT_WARRANTY_SEARCH_GRID = `${PRODUCT_WARRANTY_API}/search-grid`;
export const PRODUCT_WARRANTY_CUSTOMER = `${PRODUCT_WARRANTY_API}/get-customer`;
export const PRODUCT_WARRANTY_CUSTOMER_EXPORT = `${PRODUCT_WARRANTY_API}/customer-export-excel`;
export const PRODUCT_WARRANTY_ADD_LIST_API = `${PRODUCT_WARRANTY_API}/add-list`;
export const PRODUCT_WARRANTY_IMPORT = `${PRODUCT_WARRANTY_API}/import-excel`;
export const PRODUCT_WARRANTY_EXPORT = `${PRODUCT_WARRANTY_API}/export-excel`;
export const PRODUCT_WARRANTY_DELETE = `${PRODUCT_WARRANTY_API}/delete-many`;

export const NEWS_API = `${PREFIX}/News`;
export const NEWS_CREATE = `${NEWS_API}/create-news`;
export const NEWS_UPDATE = `${NEWS_API}/update-news`;
export const SEARCH_NEWS = `${NEWS_API}/search-grid`;
export const NEWS_VIEW_COUNT = `${NEWS_API}/view-count`;
export const NEWS_DETAIL_API = `${NEWS_API}/detail`;

export const MEASUREMENT_API = `${PREFIX}/Measurement`;
export const MEASUREMENT_UPDATE = `${MEASUREMENT_API}/update-measurement`;
export const MEASUREMENT_LEVEL_UPDATE = `${MEASUREMENT_API}/update-measurement-level`;
export const SEARCH_MEASUREMENT = `${MEASUREMENT_API}/search-grid`;
export const SEARCH_MEASUREMENT_LEVEL = `${MEASUREMENT_API}/search-grid-level`;
export const GET_ALL_MEASUREMENT = `${MEASUREMENT_API}/get-all`;

export const LANGUAGE_API = `${PREFIX}/Language`;
export const LANGUAGE_GET_ALL = `${LANGUAGE_API}/get-all`;

const REPORT_API = `${PREFIX}/Report`;
export const REPORT_GET_IMPORTANT = `${REPORT_API}/get-important`;
export const REPORT_GET_IMPORTANT_CHART = `${REPORT_API}/get-important-chart`;
export const REPORT_GET_IMPORTANT_WARRANTY = `${REPORT_API}/get-important-warranty`;
export const REPORT_GET_IMPORTANT_WARRANTY_CHART = `${REPORT_API}/get-important-warranty-chart`;
export const REPORT_GET_IMPORTANT_WARRANTY_CHART_TIME = `${REPORT_API}/get-important-warranty-chart-time`;

export const STATUS_CONFIGURATION_API = `${PREFIX}/StatusConfiguration`;
export const STATUS_CONFIGURATION_GET_ALL = `${STATUS_CONFIGURATION_API}/get-all`;
export const STATUS_CONFIGURATION_CREATE = `${STATUS_CONFIGURATION_API}/create`;
export const STATUS_CONFIGURATION_UPDATE = `${STATUS_CONFIGURATION_API}/update`;

export const WARRANTY_HISTORY_API = `${PREFIX}/WarrantyHistory`;
export const WARRANTY_HISTORY_SEARCH_GRID = `${WARRANTY_HISTORY_API}/search-grid`;
export const WARRANTY_HISTORY_GET_BY_PRODUCT = `${WARRANTY_HISTORY_API}/get-by-product`;
export const WARRANTY_HISTORY_CREATE = `${WARRANTY_HISTORY_API}/create-warranty-history`;
export const WARRANTY_HISTORY_UPDATE = `${WARRANTY_HISTORY_API}/update-warranty-history`;
export const WARRANTY_HISTORY_EXPORT_EXCEL = `${WARRANTY_HISTORY_API}/export-excel`;

export const POST_API = `${PREFIX}/Post`;
export const POST_CREATE = `${POST_API}/create-post`;
export const POST_UPDATE = `${POST_API}/update-post`;
export const SEARCH_POST = `${POST_API}/search-grid`;
export const POST_DETAIL_API = `${POST_API}/detail`;
