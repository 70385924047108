import axiosInstance from 'auth/FetchInterceptor';
import {
  PRODUCT_WARRANTY_API,
  PRODUCT_WARRANTY_ADD_LIST_API,
  PRODUCT_WARRANTY_SEARCH_GRID,
  PRODUCT_WARRANTY_CUSTOMER,
  PRODUCT_WARRANTY_CUSTOMER_EXPORT,
  PRODUCT_WARRANTY_IMPORT,
  PRODUCT_WARRANTY_EXPORT,
  PRODUCT_WARRANTY_DELETE,
} from 'constants/ApiConstant';

const ProductWarrantyService = {};

ProductWarrantyService.productWarrantySearchGrid = (data) => {
  return axiosInstance.post(PRODUCT_WARRANTY_SEARCH_GRID, data);
};

ProductWarrantyService.getCustomerByProductWarranty = (data) => {
  return axiosInstance.post(PRODUCT_WARRANTY_CUSTOMER, data);
};

ProductWarrantyService.getProductWarrantyById = (id) => {
  return axiosInstance.get(`${PRODUCT_WARRANTY_API}/${id}`, null);
};

ProductWarrantyService.addProductWarranty = (data) => {
  return axiosInstance.post(PRODUCT_WARRANTY_API, data);
};

ProductWarrantyService.addListProductWarranty = (data) => {
  return axiosInstance.post(PRODUCT_WARRANTY_ADD_LIST_API, data);
};

ProductWarrantyService.updateProductWarranty = (data) => {
  return axiosInstance.put(PRODUCT_WARRANTY_API, data);
};

ProductWarrantyService.delProductWarranty = (id) => {
  return axiosInstance.delete(`${PRODUCT_WARRANTY_API}/${id}`, null);
};

ProductWarrantyService.deleteManyProductWarranty = (data) => {
  return axiosInstance.put(PRODUCT_WARRANTY_DELETE, data);
};

ProductWarrantyService.customerInfoExportExcel = function (data) {
  return axiosInstance.post(PRODUCT_WARRANTY_CUSTOMER_EXPORT, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
};

ProductWarrantyService.importExcel = function (model) {
  const formData = new FormData();
  formData.append('file', model.file);

  return axiosInstance.post(PRODUCT_WARRANTY_IMPORT, formData, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

ProductWarrantyService.exportExcel = function (data) {
  return axiosInstance.post(PRODUCT_WARRANTY_EXPORT, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
};

export default ProductWarrantyService;
