// @ts-check
import { ProductNotFoundError } from 'views/app-views/warranty/shared';

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT_URL}/en/api/v1.0/ProductWarranty`;

/**
 * @typedef {Object} Location
 * @prop {string} id
 * @prop {string} name
 * @prop {string} nameWithType
 */

/** @typedef {Boxed<Location[]>} LocationResponse */

/** @enum {number} */
export const Gender = {
  Other: 0,
  Male: 1,
  Female: 2,
};

export const GenderString = {
  [Gender.Other]: 'Giới tính khác',
  [Gender.Male]: 'Nam',
  [Gender.Female]: 'Nữ',
};

/** @enum {number} */
export const WarrantyActiveStatus = {
  Inactive: 0,
  Active: 1,
};

/** @enum {number} */
export const WarrantyStatus = {
  Unexpired: 0,
  Expired: 1,
};

/** @enum {number} */
export const WarrantyCategory = {
  AtHome: 0,
  AtStore: 1,
  NewsImage: 6,
};

/**
 * @typedef {Object} WarrantyDto
 * @prop {string} activationDate
 * @prop {string} customerAddress
 * @prop {string} customerFullName
 * @prop {string} customerPhone
 * @prop {string} customerBirthday
 * @prop {Gender} customerGender
 */

/**
 * @typedef {Object} ProductWarranty
 * @prop {string} id
 * @prop {string} serialNumber
 * @prop {string} code
 * @prop {string} [imageUrl]
 * @prop {string} groupCode
 * @prop {string} groupName
 * @prop {WarrantyCategory} categoryId
 * @prop {WarrantyActiveStatus} status
 * @prop {WarrantyStatus} statusWarranty
 * @prop {string} warrantyPeriod
 * @prop {number} [daysLeft]
 * @prop {string} producerName
 * @prop {string} producerAddress
 * @prop {WarrantyDto} infoWarrantyDto
 */

/**
 * @template [T=unknown]
 * @typedef {Object} Boxed
 * @prop {T} data
 */

/**
 * @param {string} path
 * @param {RequestInit} [init]
 */
export function baseFetch(path, init) {
  return fetch(`${baseUrl}/${path}`, init);
}

/**
 * @param {string} codeOrPhone
 * @param {RequestInit} [init]
 */
export async function findProduct(codeOrPhone, init) {
  const params = new URLSearchParams({ code: codeOrPhone });
  const res = await baseFetch(`find-by-code?${params}`, init);
  if (!res.ok) {
    throw new ProductNotFoundError(codeOrPhone);
  }
  /** @type {Boxed<ProductWarranty[]>} */
  const json = await res.json();
  if (json.data.length <= 0) {
    throw new ProductNotFoundError(codeOrPhone);
  }
  return json.data.map((p) => ({
    ...p,
    infoWarrantyDto: p.status === WarrantyActiveStatus.Inactive ? {} : p.infoWarrantyDto,
  }));
}

/**
 * @typedef {Object} WarrantyImage
 * @prop {string} fileName
 * @prop {string} fileUrl
 * @prop {number} fileSizeB
 */

/**
 * @typedef {Object} RegisterDtoBase
 * @prop {string} id
 * @prop {WarrantyImage[]} imagesWarranty
 *
 * @typedef {RegisterDtoBase & WarrantyDto} RegisterDto
 */

/**
 *
 * @param {RegisterDto} dto
 * @param {RequestInit} [init]
 */
export function registerProduct(dto, init) {
  const body = JSON.stringify(dto);
  return baseFetch('add-info-warranty', { ...init, body, method: 'post' });
}
