import {
  auth,
  signInWithEmailAndPassword,
  signOut,
  googleAuthProvider,
  facebookAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
} from 'auth/FirebaseAuth';

const FirebaseService = {};

FirebaseService.signInEmailRequest = async (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((err) => err);
};

FirebaseService.signOutRequest = async () =>
  signOut(auth)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signInGoogleRequest = async () =>
  signInWithPopup(auth, googleAuthProvider)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signInFacebookRequest = async () =>
  signInWithPopup(auth, facebookAuthProvider)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
  createUserWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((err) => err);

export default FirebaseService;
