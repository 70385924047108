import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import Utils from 'utils';
import openNotification from 'utils/notification';
import PartnerService from 'services/partnerService';

export const getPartnerApi = createAsyncThunk(
  'store/getPartnerApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PartnerService.partnerSearchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const changePartnerStatusApi = createAsyncThunk(
  'store/changePartnerStatusApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PartnerService.partnerChangeStatus(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);
export const partnerResgiter = createAsyncThunk(
  'partner/partnerResgiter',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PartnerService.partnerRegister(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const partnerResgiterVerify = createAsyncThunk(
  'partner/partnerResgiterVerify',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PartnerService.partnerRegisterVerify(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);
export const changePrestigePartnersApi = createAsyncThunk(
  'partner/changePrestigePartnersApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await PartnerService.changePrestigePartnersApi(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);
export const delPartner = createAsyncThunk(
  'partner/delPartner',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await PartnerService.delPartner(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  formRegister: {},
  partnerData: {},
};
export const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setSendFormRegister: (state, action) => {
      state.formRegister = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(partnerResgiter.pending, (state) => {
        state.loading = true;
      })
      .addCase(partnerResgiter.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(partnerResgiter.rejected, (state) => {
        state.loading = false;
      })
      .addCase(partnerResgiterVerify.pending, (state) => {
        state.loading = true;
      })
      .addCase(partnerResgiterVerify.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(partnerResgiterVerify.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPartnerApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPartnerApi.fulfilled, (state, action) => {
        state.loading = false;
        state.partnerData = action.payload;
      })
      .addCase(getPartnerApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changePartnerStatusApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePartnerStatusApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changePartnerStatusApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changePrestigePartnersApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePrestigePartnersApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changePrestigePartnersApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(delPartner.pending, (state) => {
        state.loading = true;
      })
      .addCase(delPartner.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(delPartner.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setSendFormRegister } = partnerSlice.actions;

export default partnerSlice.reducer;
