import axiosInstance from 'auth/FetchInterceptor';
import { SUB_USER_API, LIST_SUB_USER } from 'constants/ApiConstant';

const SubUserService = {};

SubUserService.getSubUser = function (data) {
  return axiosInstance.post(LIST_SUB_USER, data);
};
SubUserService.getSubUserById = function (id) {
  return axiosInstance.get(`${SUB_USER_API}/${id}`, null);
};

export default SubUserService;
