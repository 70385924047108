import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash';
import Utils from 'utils';
import openNotification from 'utils/notification';
import Warning from 'services/WarningService';

export const warningSearchApi = createAsyncThunk(
  'warning/warningSearchApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await Warning.warningSearchApi(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const handleWarning = createAsyncThunk(
  'warning/handleWarning',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await Warning.handleWarning(data);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getWarningDetail = createAsyncThunk(
  'warning/getWarningDetail',
  async (id, { rejectWithValue }) => {
    try {
      const response = await Warning.getWarningDetail(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  warningList: [],
  countUnhandle: null,
  filterWarning: { isGetALl: true },
};
export const warningSlice = createSlice({
  name: 'warning',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setFilterWarning: (state, action) => {
      state.filterWarning = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(warningSearchApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(warningSearchApi.fulfilled, (state, action) => {
        state.loading = false;
        state.warningList = action.payload.warnings;
        state.countUnhandle = action.payload.countUnhandle;
      })
      .addCase(warningSearchApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(handleWarning.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleWarning.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(handleWarning.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setFilterWarning } = warningSlice.actions;

export default warningSlice.reducer;
