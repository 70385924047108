import axiosInstance from 'auth/FetchInterceptor';

import {
  NEWS_API,
  NEWS_CREATE,
  NEWS_UPDATE,
  SEARCH_NEWS,
  NEWS_DETAIL_API,
  NEWS_VIEW_COUNT,
} from 'constants/ApiConstant';

const NewsService = {};

NewsService.getListNews = function (data) {
  return axiosInstance.post(SEARCH_NEWS, data);
};
NewsService.getNewsById = function (id) {
  return axiosInstance.get(`${NEWS_API}/${id}`, null);
};
NewsService.createNews = function (data) {
  return axiosInstance.post(NEWS_CREATE, data);
};
NewsService.updateNews = function (data) {
  return axiosInstance.put(NEWS_UPDATE, data);
};
NewsService.delNews = function (id) {
  return axiosInstance.delete(`${NEWS_API}/${id}`, null);
};
NewsService.getNewsViewCount = function (id) {
  return axiosInstance.get(`${NEWS_VIEW_COUNT}/${id}`, null);
};
NewsService.getNewDetail = function (data) {
  const url = `${NEWS_DETAIL_API}/${data.id}/${data.customerId}/${data.langCode}`;
  return axiosInstance.get(url, null);
};

export default NewsService;
