import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import ProductTranslationService from 'services/ProductTranslationService';
import Utils from 'utils';
import openNotification from 'utils/notification';

export const getProductTranslationById = createAsyncThunk(
  'productTranslation/getProductTranslationById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProductTranslationService.getProductTranslationById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const addProductTranslationApi = createAsyncThunk(
  'productTranslation/addProductTranslationApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductTranslationService.addProductTranslation(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const updateProductTranslationApi = createAsyncThunk(
  'productTranslation/updateProductTranslationApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductTranslationService.updateProductTranslation(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  productTranslationDetail: {},
};
export const productTranslationSlice = createSlice({
  name: 'productTranslation',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setProductTranslationDetail: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductTranslationById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductTranslationById.fulfilled, (state, action) => {
        state.loading = false;
        state.productTranslationDetail = action.payload;
      })
      .addCase(getProductTranslationById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addProductTranslationApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProductTranslationApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addProductTranslationApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateProductTranslationApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProductTranslationApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateProductTranslationApi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setProductTranslationDetail } = productTranslationSlice.actions;

export default productTranslationSlice.reducer;
