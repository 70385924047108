export const WEB_ADMIN_PAGE = 'admin';
export const WEB_WARRANTY_PAGE = 'warranty';

export const ROLE_ID = {
  Admin: 1,
  Agency: 2,
};
export const SYSTEM_ROLES = [
  { value: ROLE_ID.Admin, title: 'Admin' },
  { value: ROLE_ID.Agency, title: 'Nhân viên' },
];

export const ORDER_FILTER_TIME_ID = {
  AllDay: 6,
  Today: 5,
  Yesterday: 1,
  ThisWeek: 2,
  ThisMonth: 3,
  LastMonth: 4,
};

export const ORDER_FILTER_TIME = [
  { value: ORDER_FILTER_TIME_ID.AllDay, title: 'Tất cả ngày' },
  { value: ORDER_FILTER_TIME_ID.Today, title: 'Hôm nay' },
  { value: ORDER_FILTER_TIME_ID.Yesterday, title: 'Hôm qua' },
  { value: ORDER_FILTER_TIME_ID.ThisWeek, title: 'Tuần này' },
  { value: ORDER_FILTER_TIME_ID.ThisMonth, title: 'Tháng này' },
  { value: ORDER_FILTER_TIME_ID.LastMonth, title: 'Tháng trước' },
];

export const STATUS_DRIVER_ID = {
  Rest: 0,
  Active: 1,
  Inactive: 2,
};

export const STATUS_CUSTOMER_ID = {
  Inactive: true,
  Active: false,
};

export const STATUS_CUSTOMER = [
  { value: STATUS_CUSTOMER_ID.Active, title: 'Hoạt động', color: 'green' },
  { value: STATUS_CUSTOMER_ID.Inactive, title: 'Bị chặn', color: 'red' },
];

export const STATUS_EMPLOYEE = [
  { value: STATUS_DRIVER_ID.Active, title: 'Hoạt động', color: 'green' },
  { value: STATUS_DRIVER_ID.Rest, title: 'Tạm nghỉ', color: 'grey' },
  { value: STATUS_DRIVER_ID.Inactive, title: 'Nghỉ việc', color: 'red' },
];

export const TYPE_CATEGORY_ID = {
  Online: 1,
  Warranty: 2,
};

export const TYPE_CATEGORY = [
  { value: TYPE_CATEGORY_ID.Online, title: 'Nhóm hàng kinh doanh' },
  { value: TYPE_CATEGORY_ID.Warranty, title: 'Nhóm hàng bảo hành' },
];

export const STATUS_VEHICLE_ID = {
  Inactive: 0,
  Active: 1,
};

export const STATUS_VEHICLE = [
  { value: STATUS_VEHICLE_ID.Active, title: 'Đang hoạt động', color: 'green' },
  { value: STATUS_VEHICLE_ID.Inactive, title: 'Ngưng hoạt động', color: 'red' },
];

export const STATUS_PRODUCT_ID = {
  Inactive: 0,
  Active: 1,
};

export const STATUS_PRODUCT = [
  { value: STATUS_PRODUCT_ID.Active, title: 'Còn hàng', color: 'green' },
  { value: STATUS_PRODUCT_ID.Inactive, title: 'Hết hàng', color: 'red' },
];

export const STATUS_PRODUCT_WARRANTY_ID = {
  Inactive: 0,
  Active: 1,
};

export const STATUS_PRODUCT_WARRANTY = [
  { value: STATUS_PRODUCT_WARRANTY_ID.Active, title: 'Đã kích hoạt', color: 'green' },
  { value: STATUS_PRODUCT_WARRANTY_ID.Inactive, title: 'Chưa kích hoạt', color: 'orange' },
];

export const STATUS_WARRANTY_ID = {
  Inactive: 1,
  Active: 0,
};

export const STATUS_WARRANTY_ = [
  { value: STATUS_WARRANTY_ID.Active, title: 'Còn hạn', color: 'green' },
  { value: STATUS_WARRANTY_ID.Inactive, title: 'Hết hạn', color: 'red' },
];

export const USER_GENDER = [
  { title: 'Nam', value: 1 },
  { title: 'Nữ', value: 2 },
  { title: 'Giới tính khác', value: 0 },
];

export const USER_TYPE = [
  { title: 'Người thường', value: 1 },
  { title: 'Vận động viên', value: 2 },
];

export const CATEGORY_NOTI_ID = {
  Warning: '1',
};

export const STATUS_NEWS_ID = {
  Active: 1,
  Draft: 2,
};

export const STATUS_NEWS = [
  { value: STATUS_NEWS_ID.Draft, title: 'Bản nháp', color: 'grey' },
  { value: STATUS_NEWS_ID.Active, title: 'Đã xuất bản', color: 'green' },
];

export const REPORT_FILTER_TIME_ID = {
  Today: 5,
  Yesterday: 1,
  Past7Days: 2,
  Past30Days: 3,
  Past1Year: 4,
};

export const REPORT_FILTER_TIME = [
  { value: REPORT_FILTER_TIME_ID.Yesterday, title: 'Hôm qua' },
  { value: REPORT_FILTER_TIME_ID.Today, title: 'Hôm nay' },
  { value: REPORT_FILTER_TIME_ID.Past7Days, title: '7 ngày qua' },
  { value: REPORT_FILTER_TIME_ID.Past30Days, title: '30 ngày qua' },
  { value: REPORT_FILTER_TIME_ID.Past1Year, title: '1 năm qua' },
];

export const STATUS_WARRANTY_HISTORY_ID = {
  Pending: 0,
  Processing: 1,
  Processed: 2,
  Delivered: 3,
};

export const STATUS_WARRANTY_HISTORY = [
  { value: STATUS_WARRANTY_HISTORY_ID.Pending, title: 'Chờ xử lý', color: 'grey' },
  { value: STATUS_WARRANTY_HISTORY_ID.Processing, title: 'Đang xử lý', color: 'yellow' },
  { value: STATUS_WARRANTY_HISTORY_ID.Processed, title: 'Đã xử lý', color: 'blue' },
  { value: STATUS_WARRANTY_HISTORY_ID.Delivered, title: 'Đã giao khách', color: 'green' },
];

export const STATUS_PROCEDURE_ID = {
  Home: 0,
  Store: 1,
  ServiceCenter: 2,
  Online: 3,
};

export const STATUS_PROCEDURE = [
  { value: STATUS_PROCEDURE_ID.Home, title: 'Bảo hành tại nhà' },
  { value: STATUS_PROCEDURE_ID.Store, title: 'Bảo hành tại cửa hàng' },
  { value: STATUS_PROCEDURE_ID.ServiceCenter, title: 'Bảo hành tại trung tâm bảo hành' },
  { value: STATUS_PROCEDURE_ID.Online, title: 'Bảo hành trực tuyến' },
];

export const POST_AGE_ID = {
  Nobody: -1,
  Everyone: 0,
  Infant: 1,
  Toddler: 2,
  Child: 3,
  Teenager: 4,
  Adult: 5,
  Elderly: 6,
};

export const POST_AGE = [
  { value: POST_AGE_ID.Infant, title: 'Trẻ sơ sinh ( 0-3 tuổi )' },
  { value: POST_AGE_ID.Toddler, title: 'Trẻ nhỏ ( 3-5 tuổi )' },
  { value: POST_AGE_ID.Child, title: 'Thiếu nhi ( 5-12 tuổi )' },
  { value: POST_AGE_ID.Teenager, title: 'Vị thành niên ( 12-19 tuổi )' },
  { value: POST_AGE_ID.Adult, title: 'Trưởng thành ( 19-55 tuổi )' },
  { value: POST_AGE_ID.Elderly, title: 'Người cao tuổi ( Trên 55 tuổi )' },
];
