import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import partner from './slices/partnerSlice';
import location from './slices/locationSlice';
import notification from './slices/NotificationSlice';
import customer from './slices/CustomerSlice';
import employee from './slices/EmployeeSlice';
import warning from './slices/WarningSlice';
import monitor from './slices/monitorSlide';
import product from './slices/ProductSlice';
import subUser from './slices/SubUserSlice';
import producer from './slices/ProducerSlice';
import groupProduct from './slices/GroupProductSlice';
import device from './slices/DeviceSlice';
import productWarranty from './slices/ProductWarrantySlice';
import news from './slices/NewsSlide';
import productTranslation from './slices/ProductTranslationSlice';
import measurement from './slices/MeasurementSlice';
import newsTranslation from './slices/NewsTranslationSlice';
import historyMeasures from './slices/HistoryMeasuresSlice';
import language from './slices/LanguageSlice';
import report from './slices/ReportSlice';
import { warrantyApi } from './slices/WarrantyApiSlice';
import warrantyHistory from './slices/WarrantyHistorySlice';
import statusConfiguration from './slices/StatusConfigurationSlice';
import post from './slices/PostSlice';

/** @param {Record<string, import('redux').Reducer>} asyncReducers */
const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    partner,
    location,
    customer,
    employee,
    notification,
    warning,
    monitor,
    product,
    subUser,
    producer,
    groupProduct,
    device,
    productWarranty,
    news,
    productTranslation,
    measurement,
    newsTranslation,
    historyMeasures,
    language,
    report,
    [warrantyApi.reducerPath]: warrantyApi.reducer,
    warrantyHistory,
    statusConfiguration,
    post,

    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
