import axiosInstance from 'auth/FetchInterceptor';
import { LANGUAGE_GET_ALL } from 'constants/ApiConstant';

const Language = {};

Language.getListLanguage = function () {
  return axiosInstance.get(LANGUAGE_GET_ALL, null);
};

export default Language;
