import axiosInstance from 'auth/FetchInterceptor';
import {
  SEARCH_MEASUREMENT,
  MEASUREMENT_UPDATE,
  MEASUREMENT_LEVEL_UPDATE,
  SEARCH_MEASUREMENT_LEVEL,
  GET_ALL_MEASUREMENT,
} from 'constants/ApiConstant';

const MeasurementService = {};

MeasurementService.getAllMeasurement = function () {
  return axiosInstance.get(GET_ALL_MEASUREMENT, null);
};

MeasurementService.getMeasurement = function (data) {
  return axiosInstance.post(SEARCH_MEASUREMENT, data);
};

MeasurementService.getMeasurementLevel = function (data) {
  return axiosInstance.post(SEARCH_MEASUREMENT_LEVEL, data);
};

MeasurementService.updateMeasurement = function (data) {
  return axiosInstance.put(MEASUREMENT_UPDATE, data);
};

MeasurementService.updateMeasurementLevel = function (data) {
  return axiosInstance.put(MEASUREMENT_LEVEL_UPDATE, data);
};
export default MeasurementService;
