import React from 'react';
import { useTranslation } from 'react-i18next';

// TODO will be replaced later: define props type.
// eslint-disable-next-line react/prop-types
function IntlMessage({ id, fallback }) {
  const { t } = useTranslation();

  const translate = t(id, fallback);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{translate}</>;
}

export default IntlMessage;
