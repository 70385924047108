import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import StatusConfigurationService from 'services/StatusConfigurationService';
// import Utils from 'utils';
// import openNotification from 'utils/notification';

export const getAllStatusConfigurationApi = createAsyncThunk(
  'statusConfiguration/getAllStatusConfigurationApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await StatusConfigurationService.getAllStatusConfiguration();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const addStatusConfigurationApi = createAsyncThunk(
  'statusConfiguration/addStatusConfigurationApi',
  async (data, { rejectWithValue }) => {
    try {
      // const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await StatusConfigurationService.addStatusConfiguration(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const updateStatusConfigurationApi = createAsyncThunk(
  'statusConfiguration/updateStatusConfigurationApi',
  async (data, { rejectWithValue }) => {
    try {
      // const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await StatusConfigurationService.updateStatusConfiguration(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const delStatusConfigurationApi = createAsyncThunk(
  'statusConfiguration/delStatusConfigurationApi',
  async (data, { rejectWithValue }) => {
    try {
      const { id } = data;
      const response = await StatusConfigurationService.delStatusConfiguration(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  statusConfigurationList: [],
};
export const statusConfigurationSlice = createSlice({
  name: 'statusConfiguration',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setStatusConfigurationDetail: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStatusConfigurationApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStatusConfigurationApi.fulfilled, (state, action) => {
        state.loading = false;
        state.statusConfigurationList = action.payload;
      })
      .addCase(getAllStatusConfigurationApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addStatusConfigurationApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStatusConfigurationApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addStatusConfigurationApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateStatusConfigurationApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStatusConfigurationApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateStatusConfigurationApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(delStatusConfigurationApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delStatusConfigurationApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(delStatusConfigurationApi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setStatusConfigurationDetail } = statusConfigurationSlice.actions;

export default statusConfigurationSlice.reducer;
