/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react */
// @ts-check
import { useLoaderData } from 'react-router-dom';

import { warrantyApi } from 'store/slices/WarrantyApiSlice';
import store from '../../../../store';
import { TopNavigation } from '../TopNavigation';
import { Overview } from './Overview';
import { ProductInfo } from './ProductInfo';
import { WarrantyInfo } from './WarrantyInfo';
import { ServiceHistory } from './ServiceHistory';

import style from './WarrantyPage.module.css';
import { ProductNotFoundError } from '../shared';

/** @type {import('react-router-dom').RouteObject['loader']} */
export const loader = async ({ params }) => {
  if (!params.code) {
    throw new ProductNotFoundError(params.code);
  }
  const handle = store.dispatch(warrantyApi.endpoints.getProduct.initiate(params.code));
  const result = await handle;
  handle.unsubscribe();
  if (result.error) {
    throw result.error;
  }
  return result.data;
};

/** @type {import('react-router-dom').RouteObject['action']} */
export const action = async ({ request }) => {
  const payload = await request.json();
  return store.dispatch(warrantyApi.endpoints.updateProduct.initiate(payload));
};

/** @typedef {import('services/WarrantyService').ProductWarranty} ProductWarranty */
export function WarrantyPage() {
  const product = /** @type {ProductWarranty} */ (useLoaderData());

  return (
    <div className={style.warrantyPage}>
      <TopNavigation />
      <main>
        <Overview data={product} />
        <ProductInfo data={product} />
        <WarrantyInfo data={product} />
        <ServiceHistory id={product.id} />
      </main>
    </div>
  );
}

export const Component = WarrantyPage;
