import axiosInstance from 'auth/FetchInterceptor';
import {
  CUSTOMER_API,
  CUSTOMER_CHANGE_STATUS,
  SEARCH_CUSTOMER,
  CUSTOMER_EXPORT_EXCEL,
  ALL_USER,
} from 'constants/ApiConstant';

const CustomerService = {};

CustomerService.getCustomer = function (data) {
  return axiosInstance.post(SEARCH_CUSTOMER, data);
};
CustomerService.getCustomerById = function (id) {
  return axiosInstance.get(`${CUSTOMER_API}/${id}`, null);
};

CustomerService.changeStatusCustomer = function (data) {
  return axiosInstance.put(CUSTOMER_CHANGE_STATUS, data);
};

CustomerService.getAllUser = function () {
  return axiosInstance.get(ALL_USER);
};

CustomerService.customerExportExcel = function (data) {
  return axiosInstance.post(CUSTOMER_EXPORT_EXCEL, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
};

export default CustomerService;
