import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ReportService from 'services/ReportService';

export const getImportantIndex = createAsyncThunk(
  'report/getImportantIndex',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getImportantIndex(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getImportantChart = createAsyncThunk(
  'report/getImportantChart',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getImportantChart(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getImportantWarranty = createAsyncThunk(
  'report/getImportantWarranty',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getImportantWarranty();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getImportantWarrantyChart = createAsyncThunk(
  'report/getImportantWarrantyChart',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getImportantWarrantyChart();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getImportantWarrantyChartTime = createAsyncThunk(
  'report/getImportantWarrantyChartTime',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ReportService.getImportantWarrantyChartTime(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  reportImportantList: [],
  reportImportantChart: {},
  reportImportantWarrantyList: [],
  reportImportantWarrantyChart: {},
  reportImportantWarrantyChartTime: {},
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getImportantIndex.pending, (state) => {
        state.loading = true;
      })
      .addCase(getImportantIndex.fulfilled, (state, action) => {
        state.loading = false;
        state.reportImportantList = action.payload;
      })
      .addCase(getImportantIndex.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getImportantChart.pending, (state) => {
        state.loading = true;
      })
      .addCase(getImportantChart.fulfilled, (state, action) => {
        state.loading = false;
        state.reportImportantChart = action.payload;
      })
      .addCase(getImportantChart.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getImportantWarranty.pending, (state) => {
        state.loading = true;
      })
      .addCase(getImportantWarranty.fulfilled, (state, action) => {
        state.loading = false;
        state.reportImportantWarrantyList = action.payload;
      })
      .addCase(getImportantWarranty.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getImportantWarrantyChart.pending, (state) => {
        state.loading = true;
      })
      .addCase(getImportantWarrantyChart.fulfilled, (state, action) => {
        state.loading = false;
        state.reportImportantWarrantyChart = action.payload;
      })
      .addCase(getImportantWarrantyChart.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getImportantWarrantyChartTime.pending, (state) => {
        state.loading = true;
      })
      .addCase(getImportantWarrantyChartTime.fulfilled, (state, action) => {
        state.loading = false;
        state.reportImportantWarrantyChartTime = action.payload;
      })
      .addCase(getImportantWarrantyChartTime.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = reportSlice.actions;

export default reportSlice.reducer;
