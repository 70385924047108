import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LocationService from 'services/LocationService';

export const keyCountry = 'e53a5ccb-6794-4393-ac59-a85069d0a44f';

export const getProvinceByCountry = createAsyncThunk(
  'setting/getProvinceByCountry',
  async (data, { rejectWithValue }) => {
    try {
      const response = await LocationService.provinceByCountry(keyCountry);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getDistrictByProvince = createAsyncThunk(
  'setting/getDistrictByProvince',
  async (id, { rejectWithValue }) => {
    try {
      const response = await LocationService.districtByProvince(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getWardByDistrict = createAsyncThunk(
  'setting/getWardByDistrict',
  async (id, { rejectWithValue }) => {
    try {
      const response = await LocationService.wardByCountry(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  provinceList: [],
  districtList: [],
  wardList: [],
};
export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProvinceByCountry.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProvinceByCountry.fulfilled, (state, action) => {
        state.loading = false;
        state.provinceList = action.payload;
      })
      .addCase(getProvinceByCountry.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDistrictByProvince.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDistrictByProvince.fulfilled, (state, action) => {
        state.loading = false;
        state.districtList = action.payload;
      })
      .addCase(getDistrictByProvince.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getWardByDistrict.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWardByDistrict.fulfilled, (state, action) => {
        state.loading = false;
        state.wardList = action.payload;
      })
      .addCase(getWardByDistrict.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setDistrictList, setWardList } = locationSlice.actions;

export default locationSlice.reducer;
