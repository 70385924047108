import axiosInstance from 'auth/FetchInterceptor';
import { HANDLE_WARNING, SEARCH_WARNING_DETAIL, WARNING_SEARCH_GRID } from 'constants/ApiConstant';

const WarningService = {};

WarningService.warningSearchApi = (data) => {
  return axiosInstance.post(WARNING_SEARCH_GRID, data);
};
WarningService.handleWarning = (data) => {
  return axiosInstance.post(HANDLE_WARNING, data);
};
WarningService.getWarningDetail = (id) => {
  return axiosInstance.get(`${SEARCH_WARNING_DETAIL}/${id}`, null);
};

export default WarningService;
