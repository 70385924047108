import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import NewsTranslationService from 'services/NewsTranslationService';
import Utils from 'utils';
import openNotification from 'utils/notification';

export const getNewsTranslationById = createAsyncThunk(
  'newsTranslation/getNewsTranslationById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await NewsTranslationService.getNewsTranslationById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const addNewsTranslationApi = createAsyncThunk(
  'newsTranslation/addNewsTranslationApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await NewsTranslationService.addNewsTranslation(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const updateNewsTranslationApi = createAsyncThunk(
  'newsTranslation/updateNewsTranslationApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await NewsTranslationService.updateNewsTranslation(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  newsTranslationDetail: {},
};
export const newsTranslationSlice = createSlice({
  name: 'newsTranslation',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setNewsTranslationDetail: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsTranslationById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNewsTranslationById.fulfilled, (state, action) => {
        state.loading = false;
        state.newsTranslationDetail = action.payload;
      })
      .addCase(getNewsTranslationById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addNewsTranslationApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewsTranslationApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addNewsTranslationApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateNewsTranslationApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNewsTranslationApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateNewsTranslationApi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setNewsTranslationDetail } = newsTranslationSlice.actions;

export default newsTranslationSlice.reducer;
