import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import postService from 'services/PostService';
import Utils from 'utils';
import openNotification from 'utils/notification';

export const getPostDetailApi = createAsyncThunk(
  'post/detail',
  async (data, { rejectWithValue }) => {
    try {
      const response = await postService.getPostDetail(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getPostApi = createAsyncThunk('post/getPostApi', async (data, { rejectWithValue }) => {
  try {
    const response = await postService.getListPost(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message || 'Error');
  }
});

export const getPostById = createAsyncThunk('post/getPostById', async (id, { rejectWithValue }) => {
  try {
    const response = await postService.getPostById(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message || 'Error');
  }
});

export const addPostApi = createAsyncThunk('post/addPostApi', async (data, { rejectWithValue }) => {
  try {
    const { onSuccess } = data;
    const payload = cloneDeep(data);
    delete payload.onSuccess;
    const response = await postService.createPost(payload);
    if (onSuccess) onSuccess(response);
    openNotification(
      'success',
      Utils.setLocale('admin.settings.popup.notification.success'),
      Utils.setLocale('admin.settings.popup.notification.title'),
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message || 'Error');
  }
});

export const updatePostApi = createAsyncThunk(
  'post/updatePostApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await postService.updatePost(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const delPostApi = createAsyncThunk('post/delPostApi', async (data, { rejectWithValue }) => {
  try {
    const { onSuccess, id } = data;
    const response = await postService.delPost(id);
    if (onSuccess) onSuccess(response);
    openNotification(
      'success',
      Utils.setLocale('admin.settings.popup.notification.success'),
      Utils.setLocale('admin.settings.popup.notification.title'),
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message || 'Error');
  }
});

const initialState = {
  loading: false,
  postList: [],
  postDetail: {},
  totalItems: 0,
};
export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setPostDetail: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPostApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPostApi.fulfilled, (state, action) => {
        state.loading = false;
        state.postList = action.payload;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getPostApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPostById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPostById.fulfilled, (state, action) => {
        state.loading = false;
        state.postDetail = action.payload;
      })
      .addCase(getPostById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addPostApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPostApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addPostApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updatePostApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePostApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updatePostApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(delPostApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delPostApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(delPostApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPostDetailApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPostDetailApi.fulfilled, (state, action) => {
        state.loading = false;
        state.postDetail = action.payload;
      })
      .addCase(getPostDetailApi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setPostDetail } = postSlice.actions;

export default postSlice.reducer;
