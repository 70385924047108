// @ts-check
/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react */
import { useRef, useState } from 'react';
import { matchRoutes, useNavigate, useNavigation, useSubmit } from 'react-router-dom';
import { Button, Input, Modal, Spin } from 'antd';
import { QrReader } from 'react-qr-reader';
import { CloseCircleFilled } from '@ant-design/icons';

import { warrantyRoutes as routes } from 'warrantyRoutes';

import { ReactComponent as MagnifierIcon } from 'assets/svg/magnifier.svg';
import { ReactComponent as QRIcon } from 'assets/svg/qrcode.svg';
import { Shell } from './Shell';

import style from './Shell.module.css';
import { useMessage } from '../shared';

const validHostnames = ['localhost', 'baohanh-dev.tesop.asia', 'baohanh.aguri.vn'];

export function SearchPage() {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(/** @type {import('antd').InputRef?} */ (null));
  const messageTimeout = useRef(/** @type {ReturnType<typeof setTimeout>?} */ (null));
  const navigate = useNavigate();
  const submit = useSubmit();
  const navigation = useNavigation();
  const message = useMessage();

  const showMessage = () => {
    if (!messageTimeout.current) {
      messageTimeout.current = setTimeout(() => (messageTimeout.current = null), 1000);
      message.warning('Mã QR không hợp hợp lệ, vui lòng kiểm tra lại');
    }
  };

  const searchForm = (
    <form
      className={style.searchBox}
      action='result'
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const q = formData.get('q');
        if (typeof q !== 'string' || !q.trim()) {
          return;
        }
        submit({ q: q.trim() }, { action: 'result' });
      }}
    >
      <MagnifierIcon />
      <Input
        ref={inputRef}
        name='q'
        placeholder='Nhập ID, Serial hoặc SĐT để tìm kiếm sản phẩm'
        bordered={false}
        allowClear={{
          clearIcon: <CloseCircleFilled style={{ fontSize: '1.5em', verticalAlign: 'middle' }} />,
        }}
        className={style.input}
      />
      <Button type='primary' htmlType='submit'>
        Tìm kiếm
      </Button>
    </form>
  );

  const QRButton = (
    <>
      <Button type='primary' className={style.qrButton} onClick={() => setIsOpen(true)}>
        <QRIcon />
        Tra cứu bằng QR Code
      </Button>
      <Modal open={isOpen} onCancel={() => setIsOpen(false)} destroyOnClose footer={null}>
        <QrReader
          constraints={{ facingMode: 'environment' }}
          onResult={(result) => {
            if (!result) {
              return undefined;
            }

            const text = result.getText();
            try {
              const url = new URL(text);
              if (validHostnames.includes(url.hostname)) {
                navigate(url.pathname);
              } else {
                showMessage();
              }
            } catch {
              showMessage();
            }
            return true;
          }}
        />
        <p style={{ textAlign: 'center' }}>Vui lòng đưa mã QR ra trước camera để quét</p>
      </Modal>
    </>
  );

  const matches = navigation.location && matchRoutes(routes, navigation.location);
  const spinning = matches?.at(-1)?.route.id === 'WARRANTY_PAGE';

  return (
    <Spin spinning={spinning}>
      <Shell searchForm={searchForm} QRButton={QRButton} />
    </Spin>
  );
}
