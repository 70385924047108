// @ts-check
/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react,react/prop-types */
import { Image, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useActionData, useSubmit } from 'react-router-dom';

import fallback from 'assets/images/image-fallback.png';
import { useMessage } from '../../shared';
import RegistrationForm from './RegistrationForm';

import style from './RegisterProductModal.module.css';

/** @typedef {import('./RegistrationForm').UploadItem} UploadItem */

/**
 * @param {Object} props
 * @param {import('services/WarrantyService').ProductWarranty} props.data
 * @param {boolean} props.isOpen
 * @param {function(boolean): void} props.setIsOpen
 */
export default function RegisterProductModal({ data, isOpen, setIsOpen }) {
  const [form] = useForm();
  const submit = useSubmit();
  const actionData = useActionData();
  const messageApi = useMessage();

  useEffect(() => {
    if (actionData !== null && actionData !== undefined) {
      setIsOpen(false);
    }
  }, [actionData]);

  const onCancel = () => setIsOpen(false);

  const onOk = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      messageApi.error('Xin hãy kiểm tra lại thông tin đã nhập');
      return;
    }

    const imagesWarranty = values.imagesWarranty?.map(
      /** @param {UploadItem} file */
      (file) => ({
        fileName: file.name,
        mediaLink: file.response?.url,
        fileSizeB: file.size,
      }),
    );
    submit(
      {
        ...values,
        id: data.id,
        customerPhone: transformPhone(values.customerPhone),
        imagesWarranty,
        activationDate: values.activationDate ?? new Date().toISOString(),
      },
      { method: 'post', encType: 'application/json' },
    );
  };

  return (
    <Modal
      title='Kích hoạt bảo hành'
      open={isOpen}
      onCancel={onCancel}
      onOk={onOk}
      cancelText='Huỷ'
      okText='Kích hoạt bảo hành'
      afterClose={() => form.resetFields()}
    >
      <div className={style.productPreview}>
        <Image src={data.imageUrl ?? fallback} fallback={fallback} rootClassName={style.image} />
        <h3>{data.groupName}</h3>
        <p>
          <span>
            Serial Number: <span>{data.serialNumber}</span>
          </span>
          <span>
            Bảo hành: <span>{data.warrantyPeriod ? `${data.warrantyPeriod} tháng` : '----'}</span>
          </span>
        </p>
      </div>
      <RegistrationForm form={form} />
    </Modal>
  );
}

/** @param {string} phoneNumber */
function transformPhone(phoneNumber) {
  const pattern = /^\+84|84|0084/;
  return phoneNumber.replace(pattern, '0');
}
