import axiosInstance from 'auth/FetchInterceptor';
import {
  NEWS_TRANSLATION_API,
  NEWS_TRANSLATION_CREATE,
  NEWS_TRANSLATION_UPDATE,
} from 'constants/ApiConstant';

const NewsTranslationService = {};

NewsTranslationService.getNewsTranslationById = (id) => {
  return axiosInstance.get(`${NEWS_TRANSLATION_API}/${id}`, null);
};

NewsTranslationService.addNewsTranslation = (data) => {
  return axiosInstance.post(NEWS_TRANSLATION_CREATE, data);
};

NewsTranslationService.updateNewsTranslation = (data) => {
  return axiosInstance.put(NEWS_TRANSLATION_UPDATE, data);
};

export default NewsTranslationService;
