import axiosInstance from 'auth/FetchInterceptor';
import {
  WARRANTY_HISTORY_API,
  WARRANTY_HISTORY_SEARCH_GRID,
  WARRANTY_HISTORY_CREATE,
  WARRANTY_HISTORY_UPDATE,
  WARRANTY_HISTORY_GET_BY_PRODUCT,
  WARRANTY_HISTORY_EXPORT_EXCEL,
} from 'constants/ApiConstant';

const WarrantyHistoryService = {};

WarrantyHistoryService.warrantyHistorySearchGrid = (data) => {
  return axiosInstance.post(WARRANTY_HISTORY_SEARCH_GRID, data);
};

WarrantyHistoryService.getWarrantyHistoryByProduct = (data) => {
  return axiosInstance.post(WARRANTY_HISTORY_GET_BY_PRODUCT, data);
};

WarrantyHistoryService.getWarrantyHistoryById = (id) => {
  return axiosInstance.get(`${WARRANTY_HISTORY_API}/${id}`, null);
};

WarrantyHistoryService.addWarrantyHistory = (data) => {
  return axiosInstance.post(WARRANTY_HISTORY_CREATE, data);
};

WarrantyHistoryService.updateWarrantyHistory = (data) => {
  return axiosInstance.put(WARRANTY_HISTORY_UPDATE, data);
};

WarrantyHistoryService.delWarrantyHistory = (id) => {
  return axiosInstance.delete(`${WARRANTY_HISTORY_API}/${id}`, null);
};

WarrantyHistoryService.warrantyHistoryExportExcel = function (data) {
  return axiosInstance.post(WARRANTY_HISTORY_EXPORT_EXCEL, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
};

export default WarrantyHistoryService;
