// @ts-check
/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react,react/prop-types */
import { useState } from 'react';
import { Button, Empty, Modal, Table, Tag } from 'antd';
import moment from 'moment';

import { useGetServiceHistoryQuery } from 'store/slices/WarrantyApiSlice';
import { ReactComponent as CaretCircleOutline } from 'assets/svg/caret-circle-outline.svg';
import pageStyle from './WarrantyPage.module.css';
import style from './ServiceHistory.module.css';

const { Column } = Table;

/** @enum {number} */
const ServiceStatus = {
  Pending: 0,
  Processing: 1,
  Processed: 2,
  Delivered: 3,
};

/** @enum {number} */
const StatusProcedureId = {
  Home: 0,
  Store: 1,
  ServiceCenter: 2,
  Online: 3,
};

const StatusProcedureName = {
  [StatusProcedureId.Home]: 'Tại nhà',
  [StatusProcedureId.Store]: 'Tại cửa hàng',
  [StatusProcedureId.ServiceCenter]: 'Tại trung tâm bảo hành',
  [StatusProcedureId.Online]: 'Trực tuyến',
};

/**
 * @typedef {Object} HistoryRecord
 * @prop {string} id Id của bản ghi
 * @prop {string} productWarrantyId Id của sản phẩm
 * @prop {string} content Lý do
 * @prop {string} typeWarrantyName Phân loại
 * @prop {StatusProcedureId} warrantyProcedure
 * @prop {string} resultWarrantyName Kết quả
 * @prop {string} receptionistName
 * @prop {string} technicalStaffName
 * @prop {number} status Trạng thái
 * @prop {number} temporaryPrice
 * @prop {number} totalPrice
 * @prop {string} createdTime
 * @prop {string} note
 */

/**
 * @param {Object} props
 * @param {string} props.id
 */
export function ServiceHistory({ id }) {
  const { data: history, error, isLoading } = useGetServiceHistoryQuery(id);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const selectedRecord = history?.[selectedIndex];

  return (
    <section className={pageStyle.section}>
      <h2>Lịch sử sửa chữa/bảo hành</h2>
      {!error && (
        <Table
          loading={isLoading}
          dataSource={history}
          rowKey={(r) => r.id}
          scroll={{ x: true }}
          locale={{ emptyText: <Empty description='Chưa có lịch sử sửa chữa / bảo hành' /> }}
          pagination={false}
          className={style.table}
        >
          <Column
            title='Thời gian'
            dataIndex='createdTime'
            render={
              /** @param {string} value */ (value) => {
                const momentObj = moment(value);
                return (
                  <>
                    {momentObj.format('DD/MM/YYYY')}
                    <br />
                    {momentObj.format('HH:mm')}
                  </>
                );
              }
            }
          />
          <Column title='Lý do' dataIndex='content' ellipsis />
          <Column title='Phân loại' dataIndex='typeWarrantyName' />
          <Column title='Kết quả' dataIndex='resultWarrantyName' />
          <Column
            title='Trạng thái'
            dataIndex='status'
            render={(value) => <StatusTag status={value} />}
          />
          <Column title='Thanh toán' dataIndex='totalPrice' render={formatCurrency} />
          <Column
            title='Ghi chú'
            render={(_, record, index) => (
              <Button
                type='text'
                className={style.detailsButton}
                onClick={() => {
                  setSelectedIndex(index);
                  setIsDetailsOpen(true);
                }}
              >
                Xem chi tiết
                <CaretCircleOutline />
              </Button>
            )}
          />
        </Table>
      )}
      <Modal
        open={isDetailsOpen}
        title='Chi tiết bảo hành/sửa chữa'
        footer=''
        onCancel={() => setIsDetailsOpen(false)}
        width='fit-content'
        style={{ maxWidth: 500 }}
      >
        <table className={style.detailsTable}>
          <tbody>
            <tr>
              <th>Thời gian:</th>
              <td>{moment(selectedRecord?.createdTime).format('HH:mm DD/MM/YYYY')}</td>
            </tr>
            <tr>
              <th>Lý do:</th>
              <td>{selectedRecord?.content}</td>
            </tr>
            <tr>
              <th>Phân loại:</th>
              <td>{selectedRecord?.typeWarrantyName}</td>
            </tr>
            <tr>
              <th>Phương thức bảo hành:</th>
              <td>
                {typeof selectedRecord?.warrantyProcedure === 'number' &&
                  StatusProcedureName[selectedRecord?.warrantyProcedure]}
              </td>
            </tr>
            <tr>
              <th>Nhân viên tiếp nhận:</th>
              <td>{selectedRecord?.receptionistName}</td>
            </tr>
            <tr>
              <th>Nhân viên kỹ thuật:</th>
              <td>{selectedRecord?.technicalStaffName}</td>
            </tr>
            <tr>
              <th>Báo giá tạm thời:</th>
              <td>{formatCurrency(selectedRecord?.temporaryPrice ?? null)}</td>
            </tr>
            <tr>
              <th>Kết quả:</th>
              <td>{selectedRecord?.resultWarrantyName}</td>
            </tr>
            <tr>
              <th>Trạng thái:</th>
              <td>
                {typeof selectedRecord?.status === 'number' && (
                  <StatusTag status={selectedRecord?.status} />
                )}
              </td>
            </tr>
            <tr>
              <th>Thanh toán:</th>
              <td className={style.payment}>
                {formatCurrency(selectedRecord?.totalPrice ?? null)}
              </td>
            </tr>
            <tr>
              <th>Ghi chú:</th>
              <td>{selectedRecord?.note}</td>
            </tr>
          </tbody>
        </table>
      </Modal>
    </section>
  );
}

/**
 * @param {Object} props
 * @param {ServiceStatus} props.status
 */
function StatusTag({ status }) {
  switch (status) {
    case ServiceStatus.Pending:
      return <Tag color='default'>Đang chờ xử lý</Tag>;
    case ServiceStatus.Processing:
      return <Tag color='orange'>Đang xử lý</Tag>;
    case ServiceStatus.Processed:
      return <Tag color='blue'>Đã xử lý</Tag>;
    case ServiceStatus.Delivered:
      return <Tag color='green'>Đã giao khách</Tag>;
    default:
      return null;
  }
}

const currencyFormatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  currencyDisplay: 'code',
  maximumFractionDigits: 0,
});

/** @param {number?} value */
function formatCurrency(value) {
  return value && currencyFormatter.format(value);
}
