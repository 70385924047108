import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import DeviceService from 'services/DeviceService';
import Utils from 'utils';
import openNotification from 'utils/notification';
// import Utils from 'utils';
// import openNotification from 'utils/notification';

export const getDeviceApi = createAsyncThunk(
  'device/getDeviceApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeviceService.deviceSearchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getConnectionHistoryApi = createAsyncThunk(
  'device/getConnectionHistoryApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeviceService.getConnectionHistory(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const updateDeviceApi = createAsyncThunk(
  'device/updateDeviceApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeviceService.updateDevice(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const delDeviceApi = createAsyncThunk(
  'device/delDeviceApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await DeviceService.delDevice(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const deviceExportExcelApi = createAsyncThunk(
  'device/deviceExportExcelApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeviceService.deviceExportExcel(data);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const importExcelApi = createAsyncThunk(
  'device/importExcelApi',
  async (model, { rejectWithValue }) => {
    try {
      const { onSuccess } = model;
      const response = await DeviceService.importExcel(model);
      // openNotification(
      //   'success',
      //   Utils.setLocale('admin.settings.popup.notification.success'),
      //   Utils.setLocale('admin.settings.popup.notification.title'),
      // );
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  deviceList: [],
  listConnectionHistory: [],
  deviceDetail: {},
  totalItems: 0,
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setDeviceDetail: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeviceApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeviceApi.fulfilled, (state, action) => {
        state.loading = false;
        state.deviceList = action.payload;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getDeviceApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getConnectionHistoryApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getConnectionHistoryApi.fulfilled, (state, action) => {
        state.loading = false;
        state.listConnectionHistory = action.payload;
      })
      .addCase(getConnectionHistoryApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deviceExportExcelApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(deviceExportExcelApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deviceExportExcelApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(importExcelApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(importExcelApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(importExcelApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateDeviceApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDeviceApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateDeviceApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(delDeviceApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delDeviceApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(delDeviceApi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setDeviceDetail } = deviceSlice.actions;

export default deviceSlice.reducer;
