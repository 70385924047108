// @ts-check
/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react,react/prop-types */

import { Button } from 'antd';
import { WarrantyActiveStatus } from 'services/WarrantyService';

import { ReactComponent as TickIcon } from 'assets/svg/tick.svg';
import style from './StatusButton.module.css';

/**
 * @param {Object} props
 * @param {WarrantyActiveStatus} props.status
 * @param {(arg: any) => void} [props.onClick]
 * @param {string} [props.className]
 * @param {import('react').ReactNode} [props.children]
 */
export function StatusButton({ status, onClick, className, children }) {
  return status === WarrantyActiveStatus.Inactive ? (
    <Button type='primary' htmlType='button' onClick={() => onClick?.(true)} className={className}>
      {children ?? 'Kích hoạt bảo hành ngay'}
    </Button>
  ) : (
    <div className={`${style.successBtn} ${className ?? ''}`}>
      <TickIcon /> Đã kích hoạt bảo hành
    </div>
  );
}
