import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import ProductWarrantyService from 'services/ProductWarrantyService';
import Utils from 'utils';
import openNotification from 'utils/notification';

export const getProductWarrantyApi = createAsyncThunk(
  'productWarranty/getProductWarrantyApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductWarrantyService.productWarrantySearchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getCustomerByProductWarrantyApi = createAsyncThunk(
  'productWarranty/getCustomerByProductWarrantyApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductWarrantyService.getCustomerByProductWarranty(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const customerInfoExportExcelApi = createAsyncThunk(
  'customer/customerInfoExportExcelApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductWarrantyService.customerInfoExportExcel(data);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getProductWarrantyById = createAsyncThunk(
  'productWarranty/getProductWarrantyById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProductWarrantyService.getProductWarrantyById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const addProductWarrantyApi = createAsyncThunk(
  'productWarranty/addProductWarrantyApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductWarrantyService.addProductWarranty(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const addListProductWarrantyApi = createAsyncThunk(
  'productWarranty/addListProductWarrantyApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductWarrantyService.addListProductWarranty(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const updateProductWarrantyApi = createAsyncThunk(
  'productWarranty/updateProductWarrantyApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductWarrantyService.updateProductWarranty(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const deleteManyProductWarranty = createAsyncThunk(
  'productWarranty/deleteManyProductWarranty',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductWarrantyService.deleteManyProductWarranty(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const delProductWarrantyApi = createAsyncThunk(
  'productWarranty/delProductWarrantyApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await ProductWarrantyService.delProductWarranty(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const importExcelApi = createAsyncThunk(
  'productWarranty/importExcelApi',
  async (model, { rejectWithValue }) => {
    try {
      const { onSuccess } = model;
      const response = await ProductWarrantyService.importExcel(model);
      // openNotification(
      //   'success',
      //   Utils.setLocale('admin.settings.popup.notification.success'),
      //   Utils.setLocale('admin.settings.popup.notification.title'),
      // );
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const exportExcelApi = createAsyncThunk(
  'productWarranty/productWarrantyExportExcelApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductWarrantyService.exportExcel(data);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  productWarrantyList: [],
  customerProductWarrantyList: [],
  productWarrantyDetail: {},
  totalItems: 0,
};
export const productWarrantySlice = createSlice({
  name: 'productWarranty',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setProductWarrantyDetail: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductWarrantyApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductWarrantyApi.fulfilled, (state, action) => {
        state.loading = false;
        state.productWarrantyList = action.payload;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getProductWarrantyApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCustomerByProductWarrantyApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerByProductWarrantyApi.fulfilled, (state, action) => {
        state.loading = false;
        state.customerProductWarrantyList = action.payload;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getCustomerByProductWarrantyApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProductWarrantyById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductWarrantyById.fulfilled, (state, action) => {
        state.loading = false;
        state.productWarrantyDetail = action.payload;
      })
      .addCase(getProductWarrantyById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addProductWarrantyApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProductWarrantyApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addProductWarrantyApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addListProductWarrantyApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addListProductWarrantyApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addListProductWarrantyApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateProductWarrantyApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProductWarrantyApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateProductWarrantyApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteManyProductWarranty.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteManyProductWarranty.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteManyProductWarranty.rejected, (state) => {
        state.loading = false;
      })
      .addCase(delProductWarrantyApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delProductWarrantyApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(delProductWarrantyApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(customerInfoExportExcelApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(customerInfoExportExcelApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(customerInfoExportExcelApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(importExcelApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(importExcelApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(importExcelApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(exportExcelApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportExcelApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(exportExcelApi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setProductWarrantyDetail } = productWarrantySlice.actions;

export default productWarrantySlice.reducer;
