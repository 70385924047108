import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import CustomerService from 'services/CustomerService';
import openNotification from 'utils/notification';
import Utils from 'utils';

export const getCustomerApi = createAsyncThunk(
  'customer/getCustomer',
  async (data, { rejectWithValue }) => {
    try {
      const response = await CustomerService.getCustomer(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getCustomerById = createAsyncThunk(
  'customer/getCustomerById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await CustomerService.getCustomerById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getAllUser = createAsyncThunk(
  'customer/getAllUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await CustomerService.getAllUser(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const changeStatusCustomer = createAsyncThunk(
  'customer/changeStatusCustomer',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const response = await CustomerService.changeStatusCustomer(data);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const customerExportExcelApi = createAsyncThunk(
  'customer/customerExportExcelApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await CustomerService.customerExportExcel(data);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  customerList: [],
  customerDetail: {},
  userAllList: [],
  totalItems: 0,
};
export const storeCustomerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setCustomer: (state, action) => {
      state.customerList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userAllList = action.payload;
      })
      .addCase(getAllUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCustomerApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerApi.fulfilled, (state, action) => {
        state.loading = false;
        state.customerList = action.payload;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getCustomerApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCustomerById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerById.fulfilled, (state, action) => {
        state.loading = false;
        state.customerDetail = action.payload;
      })
      .addCase(getCustomerById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changeStatusCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeStatusCustomer.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changeStatusCustomer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(customerExportExcelApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(customerExportExcelApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(customerExportExcelApi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setCustomer } = storeCustomerSlice.actions;

export default storeCustomerSlice.reducer;
