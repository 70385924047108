import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import measurementService from 'services/MeasurementService';
import Utils from 'utils';
import openNotification from 'utils/notification';

export const getAllMeasurementApi = createAsyncThunk(
  'measurement/getAllMeasurementApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await measurementService.getAllMeasurement();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getMeasurementApi = createAsyncThunk(
  'measurement/getMeasurementApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await measurementService.getMeasurement(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getMeasurementLevelApi = createAsyncThunk(
  'measurement/getMeasurementLevelApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await measurementService.getMeasurementLevel(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const updateMeasurementApi = createAsyncThunk(
  'measurement/updateMeasurementApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await measurementService.updateMeasurement(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const updateMeasurementLevelApi = createAsyncThunk(
  'measurementLevel/updateMeasurementLevelApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await measurementService.updateMeasurementLevel(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  measurementList: [],
  measurementListTree: [],
  measurementLevelList: [],
  measurementDetail: {},
  totalItems: 0,
};
export const measurementSlice = createSlice({
  name: 'measurement',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setMeasurement: (state, { payload }) => {
      if (payload !== null && payload !== undefined) {
        state.measurementList.items = payload;
      }
    },
    setMeasurementLevel: (state, { payload }) => {
      if (payload !== null && payload !== undefined) {
        state.measurementLevelList = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMeasurementApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMeasurementApi.fulfilled, (state, action) => {
        state.loading = false;
        state.measurementList = action.payload;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getMeasurementApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllMeasurementApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMeasurementApi.fulfilled, (state, action) => {
        state.loading = false;
        state.measurementListTree = action.payload;
      })
      .addCase(getAllMeasurementApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getMeasurementLevelApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMeasurementLevelApi.fulfilled, (state, action) => {
        state.loading = false;
        state.measurementLevelList = action.payload;
      })
      .addCase(getMeasurementLevelApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateMeasurementApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMeasurementApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateMeasurementApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateMeasurementLevelApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMeasurementLevelApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateMeasurementLevelApi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setMeasurement, setMeasurementLevel } = measurementSlice.actions;

export default measurementSlice.reducer;
