// @ts-check
/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react */
import { Link } from 'react-router-dom';

import { ReactComponent as NavigateBackIcon } from 'assets/svg/navigate-back.svg';

import style from './TopNavigation.module.css';

export function TopNavigation() {
  return (
    <div className={style.topNavigation}>
      <Link to='/'>
        <NavigateBackIcon />
        Tìm kiếm sản phẩm khác
      </Link>
    </div>
  );
}
