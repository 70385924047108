// @ts-check
/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react,react/prop-types */
import { DatePicker, Form, Input, Select, Upload } from 'antd';
import { skipToken } from '@reduxjs/toolkit/query/react';

import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import moment from 'moment';
import { useWatch } from 'antd/es/form/Form';
import { flushSync } from 'react-dom';
import firebaseApp from 'auth/FirebaseAuth';
import Utils from 'utils';
import {
  useGetDistrictsQuery,
  useGetProvincesQuery,
  useGetWardsQuery,
} from 'store/slices/WarrantyApiSlice';
import { Gender } from 'services/WarrantyService';
import { useMessage } from '../../shared';

import style from './RegistrationForm.module.css';
/**
 * @typedef {Object} UploadResponse
 * @prop {string} url
 * @prop {import('firebase/storage').StorageReference} ref
 * @prop {boolean} firebase
 */

/**
 * @typedef {import('antd').UploadFile<UploadResponse>} UploadItem
 */

/**
 * @param {Object} props
 * @param {import('antd').FormInstance} props.form
 */
export default function RegistrationForm({ form }) {
  const messageApi = useMessage();

  /** @type {string | undefined} */
  const provinceId = useWatch('provinceId', form);

  /** @type {string | undefined} */
  const districtId = useWatch('districtId', form);

  // @ts-ignore
  const provinces = useGetProvincesQuery();
  const districts = useGetDistrictsQuery(provinceId ?? skipToken);
  const wards = useGetWardsQuery(districtId ?? skipToken);

  /** @type {UploadItem[] | undefined} */
  const fileList = useWatch('imagesWarranty', form);

  let remainingCount = 4 - (fileList?.length ?? 0);
  /** @type {import('antd').UploadProps['beforeUpload']} */
  const beforeUpload = (file) => {
    if (remainingCount <= 0) {
      return Upload.LIST_IGNORE;
    }
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      messageApi.error(`${file.name} không đúng định dạng .png/.jpg`);
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      messageApi.error('Vui lòng không gửi ảnh quá 5MB');
      return Upload.LIST_IGNORE;
    }
    --remainingCount;
    return true;
  };

  return (
    <Form
      layout='vertical'
      form={form}
      className={style.form}
      onFieldsChange={(changedFields) => {
        if (
          changedFields.some(
            ({ name }) =>
              name === 'provinceId' || (Array.isArray(name) && name.includes('provinceId')),
          )
        ) {
          form.resetFields(['districtId', 'wardId']);
        }
      }}
    >
      <Form.Item name='customerFullName' label='Họ và tên' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name='customerPhone'
        label='Số điện thoại'
        rules={[
          { required: true },
          {
            message: 'Xin hãy nhập số điện thoại hợp lệ',
            validator: async (rule, value) => {
              if (value) {
                const validator = Utils.validatePhoneNumberVN().validator;
                return validator(rule, value);
              }
              return undefined;
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='customerGender' label='Giới tính' rules={[{ required: true }]}>
        <Select
          options={[
            { value: Gender.Male, label: 'Nam' },
            { value: Gender.Female, label: 'Nữ' },
            { value: Gender.Other, label: 'Giới tính khác' },
          ]}
        />
      </Form.Item>
      <Form.Item
        name='customerBirthday'
        label='Ngày sinh'
        rules={[
          { required: true },
          {
            validator: async (rule, value) => {
              if (value && !value.isBefore()) {
                throw new Error('Ngày sinh không hợp lệ');
              }
            },
          },
        ]}
      >
        <DatePicker className={style.fullWidth} format='DD/MM/YYYY' />
      </Form.Item>
      <Form.Item name='provinceId' label='Tỉnh/Thành phố' rules={[{ required: true }]}>
        <Select
          options={toOptions(provinces.data)}
          disabled={!provinces.isSuccess}
          loading={provinces.isLoading}
        />
      </Form.Item>
      <Form.Item name='districtId' label='Quận/Huyện' rules={[{ required: true }]}>
        <Select
          options={toOptions(districts.data)}
          disabled={!districts.isSuccess}
          loading={districts.isLoading}
        />
      </Form.Item>
      <Form.Item name='wardId' label='Xã/Phường' rules={[{ required: true }]}>
        <Select
          options={toOptions(wards.data)}
          disabled={!wards.isSuccess}
          loading={wards.isLoading}
        />
      </Form.Item>
      <Form.Item name='customerAddress' label='Địa chỉ cụ thể' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name='activationDate'
        label='Ngày mua hàng'
        rules={[{ required: true }]}
        initialValue={moment()}
      >
        <DatePicker className={style.fullWidth} format='DD/MM/YYYY' />
      </Form.Item>
      <Form.Item label='Hình ảnh' className={style.uploadWrapper}>
        <p>Vui lòng chụp hóa đơn mua hàng của bạn để chúng tôi xác minh thông tin sản phẩm</p>
        <Form.Item
          name='imagesWarranty'
          valuePropName='fileList'
          getValueFromEvent={(e) => e.fileList}
        >
          <Upload
            id='upload_image'
            listType='picture-card'
            accept='image/png,image/jpeg'
            beforeUpload={beforeUpload}
            onRemove={FirebaseDelete}
            customRequest={FirebaseUploadAdapter}
            multiple
          >
            {(fileList?.length ?? 0) < 4 && '+ Thêm ảnh'}
          </Upload>
        </Form.Item>
      </Form.Item>
    </Form>
  );
}

/** @param {import('services/WarrantyService').Location[] | undefined} locations */
function toOptions(locations) {
  return locations?.map((l) => ({ value: l.id, label: l.nameWithType }));
}

/** @param {UploadItem} file */
async function FirebaseDelete(file) {
  if (!file.response?.firebase) {
    return false;
  }
  try {
    file.response.firebase = false;
    deleteObject(file.response.ref);
  } catch {
    file.response.firebase = true;
  }
}

/** @type {import('antd').UploadProps['customRequest']} */
const FirebaseUploadAdapter = ({ file, onProgress, onError, onSuccess }) => {
  if (typeof file === 'string' || !('uid' in file)) {
    throw new Error('Expected a File instance');
  }
  const storage = getStorage(firebaseApp);
  const fileRef = ref(storage, `warranty/register/${file.uid}`);
  const task = uploadBytesResumable(fileRef, file);
  task.on(
    'state_changed',
    (snapshot) => {
      const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      onProgress?.({ percent });
    },
    (error) => onError?.(error),
    () =>
      getDownloadURL(fileRef).then((url) =>
        flushSync(() => onSuccess?.({ url, ref: fileRef, firebase: true })),
      ),
  );
};
