// @ts-check
/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react,react/prop-types */
import moment from 'moment';
import { Descriptions } from 'antd';
import { GenderString, WarrantyActiveStatus } from 'services/WarrantyService';

import { ReactComponent as ShieldIcon } from 'assets/svg/shield-outline.svg';

import pageStyle from './WarrantyPage.module.css';
import style from './WarrantyInfo.module.css';

/**
 * @param {Object} props
 * @param {import('services/WarrantyService').ProductWarranty} props.data
 */
export function WarrantyInfo(props) {
  const data = formatData(props.data);

  return (
    <section className={pageStyle.section}>
      <h2>Thông tin bảo hành</h2>
      {data.status === WarrantyActiveStatus.Inactive && (
        <div className={style.alert}>
          <ShieldIcon />
          Bạn chưa kích hoạt bảo hành, vui lòng kích hoạt sớm để hưởng chế độ bảo hành của hãng
        </div>
      )}
      <Descriptions layout='vertical' column={{ xs: 2, sm: 3 }} className={style.descriptions}>
        <Descriptions.Item label='Nhập khẩu / Phân phối'>Công ty cổ phần Nero</Descriptions.Item>
        <Descriptions.Item label='Zalo'>0983 365 635</Descriptions.Item>
        <Descriptions.Item label='Đường dây nóng'>0964 890 686</Descriptions.Item>
        <Descriptions.Item label='Serial'>{data.serialNumber}</Descriptions.Item>
        <Descriptions.Item label='Mã sản phẩm'>{data.groupCode}</Descriptions.Item>
        <Descriptions.Item label='Mã kích hoạt'>{data.code}</Descriptions.Item>
        <Descriptions.Item label='Ngày kích hoạt bảo hành'>
          {data.infoWarrantyDto.activationDate}
        </Descriptions.Item>
        <Descriptions.Item label='Thời gian bảo hành' className={style.warrantyPeriod}>
          {data.warrantyPeriod}
        </Descriptions.Item>
        <Descriptions.Item label='Số ngày bảo hành còn lại' className={style.daysLeft}>
          {data.daysLeft}
        </Descriptions.Item>
        <Descriptions.Item label='Khách hàng'>
          {data.infoWarrantyDto.customerFullName}
        </Descriptions.Item>
        <Descriptions.Item label='Địa chỉ'>
          {data.infoWarrantyDto.customerAddress}
        </Descriptions.Item>
        <Descriptions.Item label='Điện thoại'>
          {data.infoWarrantyDto.customerPhone}
        </Descriptions.Item>
        <Descriptions.Item label='Giới tính'>
          {GenderString[data.infoWarrantyDto.customerGender]}
        </Descriptions.Item>
        <Descriptions.Item label='Ngày sinh'>
          {moment(data.infoWarrantyDto.customerBirthday).format('DD/MM/YYYY')}
        </Descriptions.Item>
      </Descriptions>
    </section>
  );
}

/** @param {import('services/WarrantyService').ProductWarranty} data */
function formatData(data) {
  const { warrantyPeriod, daysLeft } = data;
  const activationDate = data.infoWarrantyDto?.activationDate;
  const newActivationDate = activationDate && moment(activationDate).format('DD/MM/YYYY');

  return {
    ...data,
    warrantyPeriod: warrantyPeriod !== null && `${warrantyPeriod} tháng`,
    daysLeft: daysLeft !== null && `${daysLeft} ngày`,
    infoWarrantyDto: { ...data.infoWarrantyDto, activationDate: newActivationDate },
  };
}
