import axiosInstance from 'auth/FetchInterceptor';

import {
  POST_API,
  POST_CREATE,
  POST_UPDATE,
  SEARCH_POST,
  POST_DETAIL_API,
} from 'constants/ApiConstant';

const PostService = {};

PostService.getListPost = function (data) {
  return axiosInstance.post(SEARCH_POST, data);
};

PostService.getPostById = function (id) {
  return axiosInstance.get(`${POST_API}/${id}`, null);
};
PostService.createPost = function (data) {
  return axiosInstance.post(POST_CREATE, data);
};

PostService.updatePost = function (data) {
  return axiosInstance.put(POST_UPDATE, data);
};

PostService.delPost = function (id) {
  return axiosInstance.delete(`${POST_API}/${id}`, null);
};

PostService.getPostDetail = function (data) {
  const url = `${POST_DETAIL_API}/${data.id}/${data.customerId}`;
  return axiosInstance.get(url, null);
};

export default PostService;
