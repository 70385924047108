import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    title: 'Đăng nhập',
    component: React.lazy(() => import('views/auth-views/authentication/login')),
  },
  {
    key: 'register',
    path: `${AUTH_PREFIX_PATH}/register`,
    title: 'Đăng ký',
    component: React.lazy(() => import('views/auth-views/authentication/partner-register')),
  },
  {
    key: 'forgot-password',
    title: 'Quên mật khẩu',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
  },
  {
    key: 'reset-password',
    path: '/reset-password',
    title: 'Đặt lại mật khẩu',
    component: React.lazy(() => import('views/auth-views/authentication/reset-password')),
  },
  {
    key: 'partner-register',
    path: '/partner-register',
    title: 'Đăng ký',
    component: React.lazy(() => import('views/auth-views/authentication/partner-register/verify')),
  },
  {
    key: 'page-news',
    path: '/full-page/news',
    title: '',
    component: React.lazy(() => import('views/full-page/news')),
  },
  {
    key: 'page-product',
    path: '/full-page/product',
    title: '',
    component: React.lazy(() => import('views/full-page/product')),
  },
  {
    key: 'healthKit-guide',
    path: '/full-page/health-kit-guide',
    title: '',
    component: React.lazy(() => import('views/full-page/healthKitGuide')),
  },
  {
    key: 'page-post',
    path: '/full-page/post',
    title: '',
    component: React.lazy(() => import('views/full-page/post')),
  },
];

export const protectedRoutes = [
  {
    key: 'dashboard.default',
    title: 'DASHBOARD',
    path: `${APP_PREFIX_PATH}/aguri-monitor/dashboard`,
    component: React.lazy(() => import('views/app-views/admin/aguri-monitor/dashboard')),
  },
  {
    key: 'monitor.dashboard',
    title: 'Dashboard',
    path: `${APP_PREFIX_PATH}/aguri-monitor/dashboard`,
    component: React.lazy(() => import('views/app-views/admin/aguri-monitor/dashboard')),
  },

  // product
  {
    key: 'product.online-products',
    title: 'online-products',
    path: `${APP_PREFIX_PATH}/aguri-product/online-products`,
    component: React.lazy(() => import('views/app-views/admin/aguri-product/product')),
  },
  {
    key: 'product.online-products.uc',
    path: `${APP_PREFIX_PATH}/aguri-product/online-products/uc`,
    title: '',
    component: React.lazy(() => import('views/app-views/admin/aguri-product/product/feature')),
  },
  {
    key: 'product.online-products.uc',
    path: `${APP_PREFIX_PATH}/aguri-product/online-products/uc/:id`,
    title: '',
    component: React.lazy(() => import('views/app-views/admin/aguri-product/product/feature')),
  },
  {
    key: 'product.product-category',
    title: 'product-category',
    path: `${APP_PREFIX_PATH}/aguri-product/product-category`,
    component: React.lazy(() => import('views/app-views/admin/aguri-product/productCategory')),
  },
  {
    key: 'product.product-category.uc',
    path: `${APP_PREFIX_PATH}/aguri-product/product-category/uc`,
    title: '',
    component: React.lazy(() =>
      import('views/app-views/admin/aguri-product/productCategory/feature/CreateAndUpdate'),
    ),
  },
  {
    key: 'product.product-category.uc',
    path: `${APP_PREFIX_PATH}/aguri-product/product-category/uc/:id`,
    title: '',
    component: React.lazy(() =>
      import('views/app-views/admin/aguri-product/productCategory/feature/CreateAndUpdate'),
    ),
  },

  // employee
  {
    key: 'settings.employee',
    title: 'employee',
    path: `${APP_PREFIX_PATH}/aguri-settings/employee`,
    component: React.lazy(() => import('views/app-views/admin/aguri-settings/employee')),
  },
  {
    key: 'settings.employee.uc',
    path: `${APP_PREFIX_PATH}/aguri-settings/employee/uc`,
    title: '',
    component: React.lazy(() =>
      import('views/app-views/admin/aguri-settings/employee/feature/CreateAndUpdate'),
    ),
  },
  {
    key: 'settings.employee.uc',
    path: `${APP_PREFIX_PATH}/aguri-settings/employee/uc/:id`,
    title: '',
    component: React.lazy(() =>
      import('views/app-views/admin/aguri-settings/employee/feature/CreateAndUpdate'),
    ),
  },

  // device
  {
    key: 'device.management',
    title: 'device-management',
    path: `${APP_PREFIX_PATH}/aguri-device/device`,
    component: React.lazy(() => import('views/app-views/admin/aguri-device/device')),
  },
  {
    key: 'device.user-device',
    title: 'user-device',
    path: `${APP_PREFIX_PATH}/aguri-device/user-device`,
    component: React.lazy(() => import('views/app-views/admin/aguri-device/user-device')),
  },
  {
    key: 'device.measurement',
    title: 'measurement',
    path: `${APP_PREFIX_PATH}/aguri-device/measurement`,
    component: React.lazy(() => import('views/app-views/admin/aguri-device/measurement')),
  },

  // warranty
  {
    key: 'warranty.management',
    title: 'warranty',
    path: `${APP_PREFIX_PATH}/aguri-warranty/warranty`,
    component: React.lazy(() => import('views/app-views/admin/aguri-warranty/warranty')),
  },
  {
    key: 'warranty.management.uc',
    path: `${APP_PREFIX_PATH}/aguri-warranty/warranty/uc`,
    title: '',
    component: React.lazy(() => import('views/app-views/admin/aguri-warranty/warranty/feature')),
  },
  {
    key: 'warranty.management.uc',
    path: `${APP_PREFIX_PATH}/aguri-warranty/warranty/uc/:id`,
    title: '',
    component: React.lazy(() => import('views/app-views/admin/aguri-warranty/warranty/feature')),
  },
  {
    key: 'warranty.history',
    title: 'warranty-history',
    path: `${APP_PREFIX_PATH}/aguri-warranty/history`,
    component: React.lazy(() => import('views/app-views/admin/aguri-warranty/warrantyHistory')),
  },
  {
    key: 'warranty.customer',
    title: 'warranty-customer',
    path: `${APP_PREFIX_PATH}/aguri-warranty/customer`,
    component: React.lazy(() => import('views/app-views/admin/aguri-warranty/customer')),
  },
  {
    key: 'warranty.producer',
    title: 'producer',
    path: `${APP_PREFIX_PATH}/aguri-warranty/producer`,
    component: React.lazy(() => import('views/app-views/admin/aguri-warranty/producer')),
  },
  {
    key: 'warranty.producer.uc',
    path: `${APP_PREFIX_PATH}/aguri-warranty/producer/uc`,
    title: '',
    component: React.lazy(() =>
      import('views/app-views/admin/aguri-warranty/producer/feature/CreateAndUpdate'),
    ),
  },
  {
    key: 'warranty.producer.uc',
    path: `${APP_PREFIX_PATH}/aguri-warranty/producer/uc/:id`,
    title: '',
    component: React.lazy(() =>
      import('views/app-views/admin/aguri-warranty/producer/feature/CreateAndUpdate'),
    ),
  },
  {
    key: 'warranty.product-category',
    title: '',
    path: `${APP_PREFIX_PATH}/aguri-warranty/product-category`,
    component: React.lazy(() => import('views/app-views/admin/aguri-warranty/productCategory')),
  },
  {
    key: 'warranty.product-category.uc',
    path: `${APP_PREFIX_PATH}/aguri-warranty/product-category/uc`,
    title: '',
    component: React.lazy(() =>
      import('views/app-views/admin/aguri-warranty/productCategory/feature/CreateAndUpdate'),
    ),
  },
  {
    key: 'warranty.product-category.uc',
    path: `${APP_PREFIX_PATH}/aguri-warranty/product-category/uc/:id`,
    title: '',
    component: React.lazy(() =>
      import('views/app-views/admin/aguri-warranty/productCategory/feature/CreateAndUpdate'),
    ),
  },

  // information
  {
    key: 'information.news',
    title: 'news',
    path: `${APP_PREFIX_PATH}/aguri-information/news`,
    component: React.lazy(() => import('views/app-views/admin/aguri-information/news')),
  },
  {
    key: 'information.news.uc',
    path: `${APP_PREFIX_PATH}/aguri-information/news/uc`,
    title: '',
    component: React.lazy(() => import('views/app-views/admin/aguri-information/news/feature')),
  },
  {
    key: 'information.news.uc',
    path: `${APP_PREFIX_PATH}/aguri-information/news/uc/:id`,
    title: '',
    component: React.lazy(() => import('views/app-views/admin/aguri-information/news/feature')),
  },

  {
    key: 'information.post',
    title: 'post',
    path: `${APP_PREFIX_PATH}/aguri-information/post`,
    component: React.lazy(() => import('views/app-views/admin/aguri-information/post')),
  },
  {
    key: 'information.post.uc',
    path: `${APP_PREFIX_PATH}/aguri-information/post/uc`,
    title: '',
    component: React.lazy(() => import('views/app-views/admin/aguri-information/post/feature')),
  },
  {
    key: 'information.post.uc',
    path: `${APP_PREFIX_PATH}/aguri-information/post/uc/:id`,
    title: '',
    component: React.lazy(() => import('views/app-views/admin/aguri-information/post/feature')),
  },

  // preview
  {
    key: 'preview-product',
    path: '/preview/product',
    title: '',
    component: React.lazy(() => import('views/full-page/product')),
  },
  {
    key: 'preview-news',
    path: '/preview/news',
    title: '',
    component: React.lazy(() => import('views/full-page/news')),
  },
  {
    key: 'preview-post',
    path: '/preview/post',
    title: '',
    component: React.lazy(() => import('views/full-page/post')),
  },
];
