import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY, AUTHENTICATED_ENTRY_ADMIN } from 'configs/AppConfig';

import { SYSTEM_ROLES } from 'constants/AuthConstant';

function PublicRoute() {
  const { token, userRoles } = useSelector((state) => state.auth);
  let toPath = '';
  if (userRoles.includes(SYSTEM_ROLES.OperatorAdmin)) {
    toPath = AUTHENTICATED_ENTRY_ADMIN;
  } else if (
    userRoles.includes(SYSTEM_ROLES.PartnerAdmin) ||
    userRoles.includes(SYSTEM_ROLES.StoreAdmin)
  ) {
    toPath = AUTHENTICATED_ENTRY;
  }
  return token ? <Navigate className='ant-menu-item-selected' to={toPath} /> : <Outlet />;
}

export default PublicRoute;
