// @ts-check
/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react,react/prop-types */
import pageStyle from './WarrantyPage.module.css';
import style from './ProductInfo.module.css';

/**
 * @param {Object} props
 * @param {import('services/WarrantyService').ProductWarranty} props.data
 */
export function ProductInfo({ data }) {
  return (
    <section className={pageStyle.section}>
      <h2 className={pageStyle.heading}>Thông tin sản phẩm</h2>
      <table className={style.table}>
        <tbody>
          <tr>
            <th>Nhà sản xuất</th>
            <td>{data.producerName}</td>
          </tr>
          <tr>
            <th>Địa chỉ nơi sản xuất</th>
            <td>{data.producerAddress}</td>
          </tr>
          <tr>
            <th>Năm sản xuất</th>
            <td>Xem trên vỏ hộp</td>
          </tr>
          <tr>
            <th>Hướng dẫn sử dụng</th>
            <td>Xem trong hướng dẫn kèm theo máy</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}
