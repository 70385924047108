// @ts-check
/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react,react/prop-types */
import { ReactComponent as ThunderIcon } from 'assets/svg/thunder-outline.svg';
import { ReactComponent as ShieldIcon } from 'assets/svg/shield-outline.svg';
import { ReactComponent as LicenseIcon } from 'assets/svg/license-global-outline.svg';
import { ReactComponent as Decorator } from 'assets/svg/landing-page-decorator.svg';

import style from './Shell.module.css';

/**
 * @param {Object} props
 * @param {React.ReactNode} props.searchForm
 * @param {React.ReactNode} props.QRButton
 */
export function Shell(props) {
  return (
    <div className={style.landingPage}>
      <main>
        <h1>AGURI CARE</h1>
        <h2>Trung tâm tra cứu sản phẩm và kích hoạt bảo hành</h2>
        <div className={style.searchWrapper}>
          {props.searchForm}
          <span>hoặc</span>
          {props.QRButton}
        </div>
        <Decorator className={style.decorator} />
        <section className={style.features}>
          <div className={style.feature}>
            <div className={style.iconWrapper}>
              <ThunderIcon />
            </div>
            <span className={style.featureBrief}>Tra cứu thông tin tức thời</span>
            <p>
              Thông tin sản phẩm chính hãng được tra cứu nhanh chóng và minh bạch bằng vài thao tác
              trên hệ thống.
            </p>
          </div>
          <div className={style.feature}>
            <div className={style.iconWrapper}>
              <ShieldIcon />
            </div>
            <span className={style.featureBrief}>Kích hoạt bảo hành thật dễ</span>
            <p>
              Chỉ bằng vài thao tác sản phẩm của bạn sẽ được hưởng chế độ bảo hành tuyệt vời của
              AGURI.
            </p>
          </div>
          <div className={style.feature}>
            <div className={style.iconWrapper}>
              <LicenseIcon />
            </div>
            <span className={style.featureBrief}>Bảo hành điện tử</span>
            <p>
              Hãy quên đi thẻ bảo hành. Chỉ cần cung cấp ID sản phẩm và chúng tôi sẽ bảo hành sản
              phẩm một cách chu đáo nhất.
            </p>
          </div>
        </section>
      </main>
    </div>
  );
}

export const Component = Shell;
