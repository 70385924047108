import axiosInstance from 'auth/FetchInterceptor';
import {
  PRODUCT_API,
  PRODUCT_SEARCH_GRID,
  PRODUCTS_CHANGE_STATUS,
  PRODUCTS_GROUP_CATEGORY,
  PRODUCT_CREATE,
  PRODUCT_UPDATE,
  PRODUCT_GET_ALL,
  PRODUCT_DETAIL_API,
} from 'constants/ApiConstant';

const ProductService = {};

ProductService.getAllProduct = function () {
  return axiosInstance.get(PRODUCT_GET_ALL, null);
};

ProductService.productSearchGrid = (data) => {
  return axiosInstance.post(PRODUCT_SEARCH_GRID, data);
};

ProductService.getProductById = (id) => {
  return axiosInstance.get(`${PRODUCT_API}/${id}`, null);
};

ProductService.addProduct = (data) => {
  return axiosInstance.post(PRODUCT_CREATE, data);
};

ProductService.updateProduct = (data) => {
  return axiosInstance.put(PRODUCT_UPDATE, data);
};

ProductService.delProduct = (id) => {
  return axiosInstance.delete(`${PRODUCT_API}/${id}`, null);
};

ProductService.groupCategory = (data) => {
  return axiosInstance.post(PRODUCTS_GROUP_CATEGORY, data);
};

ProductService.changeStatusProducts = (data) => {
  return axiosInstance.put(PRODUCTS_CHANGE_STATUS, data);
};

ProductService.getProductDetail = function (data) {
  const url = `${PRODUCT_DETAIL_API}/${data.id}/${data.langCode}`;
  return axiosInstance.get(url, null);
};

export default ProductService;
