import axiosInstance from 'auth/FetchInterceptor';
import {
  PARTNER_SEARCH_GRID,
  PARTNER_API,
  PARTNER_CHANGE_PRESTIGE,
  PARTNER_CHANGE_STATUS_API,
  PARTNER_REGISTER_API,
  PARTNER_REGISTER_VERIFY_API,
} from 'constants/ApiConstant';

const PartnerService = {};

PartnerService.partnerSearchGrid = (data) => {
  return axiosInstance.post(PARTNER_SEARCH_GRID, data);
};

PartnerService.partnerChangeStatus = (data) => {
  return axiosInstance.post(PARTNER_CHANGE_STATUS_API, data);
};

PartnerService.partnerRegister = (data) => {
  return axiosInstance.post(PARTNER_REGISTER_API, data);
};

PartnerService.partnerRegisterVerify = (data) => {
  return axiosInstance.post(PARTNER_REGISTER_VERIFY_API, data);
};

PartnerService.changePrestigePartnersApi = (data) => {
  return axiosInstance.put(PARTNER_CHANGE_PRESTIGE, data);
};
PartnerService.delPartner = (id) => {
  return axiosInstance.delete(`${PARTNER_API}/id?id=${id}`, null);
};

export default PartnerService;
