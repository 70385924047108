import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import ProductService from 'services/ProductService';
import Utils from 'utils';
import openNotification from 'utils/notification';

export const getProductDetailApi = createAsyncThunk(
  'product/detailProduct',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductService.getProductDetail(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getAllProductApi = createAsyncThunk(
  'product/getAllProductApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductService.getAllProduct();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getProductApi = createAsyncThunk(
  'product/getProductApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductService.productSearchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getProductById = createAsyncThunk(
  'product/getProductById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProductService.getProductById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const addProductApi = createAsyncThunk(
  'product/addProductApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductService.addProduct(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const updateProductApi = createAsyncThunk(
  'product/updateProductApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductService.updateProduct(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const delProductApi = createAsyncThunk(
  'product/delProductApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await ProductService.delProduct(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const groupCategoryApi = createAsyncThunk(
  'product/distributeProductsApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductService.groupCategory(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const changeStatusProductsApi = createAsyncThunk(
  'product/changeStatusProductsApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductService.changeStatusProducts(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  productList: [],
  productListTree: [],
  productDetail: {},
};
export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setProductDetail: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductApi.fulfilled, (state, action) => {
        state.loading = false;
        state.productList = action.payload;
      })
      .addCase(getProductApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProductApi.fulfilled, (state, action) => {
        state.loading = false;
        state.productListTree = action.payload;
      })
      .addCase(getAllProductApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProductById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.loading = false;
        state.productDetail = action.payload;
      })
      .addCase(getProductById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProductApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addProductApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProductApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateProductApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(delProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delProductApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(delProductApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(groupCategoryApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(groupCategoryApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(groupCategoryApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(changeStatusProductsApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeStatusProductsApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changeStatusProductsApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProductDetailApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductDetailApi.fulfilled, (state, action) => {
        state.loading = false;
        state.productDetail = action.payload;
      })
      .addCase(getProductDetailApi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setProductDetail } = productSlice.actions;

export default productSlice.reducer;
