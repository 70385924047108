import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import ProducerService from 'services/ProducerService';
import Utils from 'utils';
import openNotification from 'utils/notification';

export const getProducerApi = createAsyncThunk(
  'producer/getProducerApi',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProducerService.producerSearchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getProducerById = createAsyncThunk(
  'producer/getProducerById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProducerService.getProducerById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const addProducerApi = createAsyncThunk(
  'producer/addProducerApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProducerService.addProducer(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const updateProducerApi = createAsyncThunk(
  'producer/updateProducerApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProducerService.updateProducer(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const delProducerApi = createAsyncThunk(
  'producer/delProducerApi',
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await ProducerService.delProducer(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        'success',
        Utils.setLocale('admin.settings.popup.notification.success'),
        Utils.setLocale('admin.settings.popup.notification.title'),
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const importExcelApi = createAsyncThunk(
  'producer/importExcelApi',
  async (model, { rejectWithValue }) => {
    try {
      const { onSuccess } = model;
      const response = await ProducerService.importExcel(model);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  producerList: [],
  producerDetail: {},
};

export const producerSlice = createSlice({
  name: 'producer',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setProducerDetail: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducerApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProducerApi.fulfilled, (state, action) => {
        state.loading = false;
        state.producerList = action.payload;
      })
      .addCase(getProducerApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProducerById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProducerById.fulfilled, (state, action) => {
        state.loading = false;
        state.producerDetail = action.payload;
      })
      .addCase(getProducerById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addProducerApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProducerApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addProducerApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateProducerApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProducerApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateProducerApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(delProducerApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delProducerApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(delProducerApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(importExcelApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(importExcelApi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(importExcelApi.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setProducerDetail } = producerSlice.actions;

export default producerSlice.reducer;
