import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HistoryMeasuresService from 'services/HistoryMeasuresService';

export const getListHistoryMeasuresApi = createAsyncThunk(
  'historyMeasures/getListHistoryMeasures',
  async (data, { rejectWithValue }) => {
    try {
      const response = await HistoryMeasuresService.getListHistoryMeasures(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getHistoryMeasuresApi = createAsyncThunk(
  'historyMeasures/getHistoryMeasures',
  async (data, { rejectWithValue }) => {
    try {
      const response = await HistoryMeasuresService.getHistoryMeasures(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

export const getHistoryMeasuresById = createAsyncThunk(
  'historyMeasures/getHistoryMeasuresById',
  async (data, { rejectWithValue }) => {
    try {
      const response = await HistoryMeasuresService.getHistoryMeasuresById(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || 'Error');
    }
  },
);

const initialState = {
  loading: false,
  historyMeasuresList: [],
  historyMeasures: {},
  historyMeasuresDetail: {},
};
export const storeHistoryMeasuresSlice = createSlice({
  name: 'historyMeasures',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setHistoryMeasures: (state, action) => {
      state.historyMeasuresList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListHistoryMeasuresApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getListHistoryMeasuresApi.fulfilled, (state, action) => {
        state.loading = false;
        state.historyMeasuresList = action.payload;
      })
      .addCase(getListHistoryMeasuresApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getHistoryMeasuresApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHistoryMeasuresApi.fulfilled, (state, action) => {
        state.loading = false;
        state.historyMeasures = action.payload;
      })
      .addCase(getHistoryMeasuresApi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getHistoryMeasuresById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHistoryMeasuresById.fulfilled, (state, action) => {
        state.loading = false;
        state.historyMeasuresDetail = action.payload;
      })
      .addCase(getHistoryMeasuresById.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setHistoryMeasures } = storeHistoryMeasuresSlice.actions;

export default storeHistoryMeasuresSlice.reducer;
