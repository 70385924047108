import axiosInstance from 'auth/FetchInterceptor';
import {
  GROUP_PRODUCT_API,
  GROUP_PRODUCT_SEARCH_GRID,
  GROUP_PRODUCT_CREATE,
  GROUP_PRODUCT_UPDATE,
  GROUP_PRODUCT_IMPORT_EXCEL,
} from 'constants/ApiConstant';

const GroupProductService = {};

GroupProductService.groupProductSearchGrid = (data) => {
  return axiosInstance.post(GROUP_PRODUCT_SEARCH_GRID, data);
};

GroupProductService.getGroupProductById = (id) => {
  return axiosInstance.get(`${GROUP_PRODUCT_API}/${id}`, null);
};

GroupProductService.addGroupProduct = (data) => {
  return axiosInstance.post(GROUP_PRODUCT_CREATE, data);
};

GroupProductService.updateGroupProduct = (data) => {
  return axiosInstance.put(GROUP_PRODUCT_UPDATE, data);
};

GroupProductService.delGroupProduct = (id) => {
  return axiosInstance.delete(`${GROUP_PRODUCT_API}/${id}`, null);
};

GroupProductService.importExcel = function (model) {
  const formData = new FormData();
  formData.append('file', model.file);

  return axiosInstance.post(GROUP_PRODUCT_IMPORT_EXCEL, formData, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export default GroupProductService;
