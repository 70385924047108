import axiosInstance from 'auth/FetchInterceptor';
import {
  DEVICE_API,
  DEVICE_SEARCH_GRID,
  CONNECTION_HISTORY,
  DEVICE_EXPORT_EXCEL,
  DEVICE_IMPORT_EXCEL,
  DEVICE_UPDATE,
} from 'constants/ApiConstant';

const DeviceService = {};

DeviceService.deviceSearchGrid = (data) => {
  return axiosInstance.post(DEVICE_SEARCH_GRID, data);
};

DeviceService.updateDevice = function (data) {
  return axiosInstance.put(DEVICE_UPDATE, data);
};

DeviceService.delDevice = function (id) {
  return axiosInstance.delete(`${DEVICE_API}/${id}`, null);
};

DeviceService.getConnectionHistory = function (data) {
  return axiosInstance.post(CONNECTION_HISTORY, data);
};

DeviceService.deviceExportExcel = function (data) {
  return axiosInstance.post(DEVICE_EXPORT_EXCEL, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
};

DeviceService.importExcel = function (model) {
  const formData = new FormData();
  formData.append('file', model.file);

  return axiosInstance.post(DEVICE_IMPORT_EXCEL, formData, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export default DeviceService;
