import axiosInstance from 'auth/FetchInterceptor';
import {
  STATUS_CONFIGURATION_API,
  STATUS_CONFIGURATION_CREATE,
  STATUS_CONFIGURATION_GET_ALL,
  STATUS_CONFIGURATION_UPDATE,
} from 'constants/ApiConstant';

const StatusConfigurationService = {};

StatusConfigurationService.getAllStatusConfiguration = function () {
  return axiosInstance.get(STATUS_CONFIGURATION_GET_ALL, null);
};

StatusConfigurationService.addStatusConfiguration = (data) => {
  return axiosInstance.post(STATUS_CONFIGURATION_CREATE, data);
};

StatusConfigurationService.delStatusConfiguration = (id) => {
  return axiosInstance.delete(`${STATUS_CONFIGURATION_API}/${id}`, null);
};

StatusConfigurationService.updateStatusConfiguration = (data) => {
  return axiosInstance.put(STATUS_CONFIGURATION_UPDATE, data);
};

export default StatusConfigurationService;
