import axiosInstance from 'auth/FetchInterceptor';
import {
  HISTORY_MEASURES_API,
  LIST_HISTORY_MEASURES,
  HISTORY_MEASURES,
} from 'constants/ApiConstant';

const HistoryMeasuresService = {};

HistoryMeasuresService.getListHistoryMeasures = function (data) {
  return axiosInstance.post(LIST_HISTORY_MEASURES, data);
};

HistoryMeasuresService.getHistoryMeasures = function (data) {
  return axiosInstance.post(HISTORY_MEASURES, data);
};

HistoryMeasuresService.getHistoryMeasuresById = function (data) {
  return axiosInstance.get(`${HISTORY_MEASURES_API}/${data.id}/${data.idSample}`, null);
};

export default HistoryMeasuresService;
