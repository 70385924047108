import { SearchPage } from 'views/app-views/warranty/SearchPage/SearchPage';
import {
  WarrantyPage,
  action as warrantyPageAction,
  loader as warrantyPageLoader,
} from 'views/app-views/warranty/WarrantyPage/WarrantyPage';
import { ResultPage, loader as resultPageLoader } from 'views/app-views/warranty/ResultPage';
import { ErrorBoundary } from 'views/app-views/warranty/ErrorBoundary';

/** @type {import('react-router-dom').RouteObject[]} */
export const warrantyRoutes = [
  {
    async lazy() {
      const { Layout } = await import('./views/app-views/warranty/Layout');
      return { Component: Layout };
    },
    children: [
      {
        ErrorBoundary,
        children: [
          { index: true, Component: SearchPage },
          {
            path: 'result',
            Component: ResultPage,
            loader: resultPageLoader,
          },
          {
            path: ':code',
            id: 'WARRANTY_PAGE',
            Component: WarrantyPage,
            loader: warrantyPageLoader,
            action: warrantyPageAction,
          },
        ],
      },
    ],
  },
];
