// @ts-check
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { findProduct } from 'services/WarrantyService';
import { ProductNotFoundError } from 'views/app-views/warranty/shared';

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT_URL}/en/api/v1`;
const locationPath = 'Location';

/** @typedef {import('services/WarrantyService').LocationResponse} LocationResponse */

export const warrantyApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ['Product'],
  endpoints: (builder) => ({
    getProduct: builder.query({
      queryFn:
        /** @param {string} code */
        async (code) => {
          try {
            const products = await findProduct(code);
            return { data: products[0] };
          } catch (error) {
            return {
              error: {
                status: 'CUSTOM_ERROR',
                error: 'Product not found',
                data: new ProductNotFoundError(code),
              },
            };
          }
        },
      providesTags: (result, error, code) => [{ type: 'Product', id: code }],
    }),
    updateProduct: builder.mutation({
      query:
        /**
         * @param {Object} props
         * @param {string} props.code
         */
        ({ code, ...body }) => ({
          url: `ProductWarranty/add-info-warranty`,
          method: 'POST',
          body,
        }),
      invalidatesTags: ['Product'],
    }),
    getServiceHistory: builder.query({
      /** @param {string} id */
      query: (id) => ({
        url: `WarrantyHistory/get-by-product`,
        method: 'POST',
        body: {
          productWarrantyId: id,
        },
      }),
      /** @param {{data: import('views/app-views/warranty/WarrantyPage/ServiceHistory').HistoryRecord[]}} value */
      transformResponse: (value) => value.data,
    }),
    getProvinces: builder.query({
      query: () => `${locationPath}/province-by-country/e53a5ccb-6794-4393-ac59-a85069d0a44f`,
      /** @param {LocationResponse} value */
      transformResponse: (value) => value.data,
    }),
    getDistricts: builder.query({
      /** @param {string} provinceId */
      query: (provinceId) => `${locationPath}/district-by-province/${provinceId}`,
      /** @param {LocationResponse} value */
      transformResponse: (value) => value.data,
    }),
    getWards: builder.query({
      query: (districtId) => `${locationPath}/ward-by-district/${districtId}`,
      /** @param {LocationResponse} value */
      transformResponse: (value) => value.data,
    }),
  }),
});

export const {
  useGetServiceHistoryQuery,
  useGetProductQuery,
  useGetProvincesQuery,
  useGetDistrictsQuery,
  useGetWardsQuery,
} = warrantyApi;
