import axiosInstance from 'auth/FetchInterceptor';
import {
  PRODUCT_TRANSLATION_API,
  PRODUCT_TRANSLATION_CREATE,
  PRODUCT_TRANSLATION_UPDATE,
} from 'constants/ApiConstant';

const ProductTranslationService = {};

ProductTranslationService.getProductTranslationById = (id) => {
  return axiosInstance.get(`${PRODUCT_TRANSLATION_API}/${id}`, null);
};

ProductTranslationService.addProductTranslation = (data) => {
  return axiosInstance.post(PRODUCT_TRANSLATION_CREATE, data);
};

ProductTranslationService.updateProductTranslation = (data) => {
  return axiosInstance.put(PRODUCT_TRANSLATION_UPDATE, data);
};

export default ProductTranslationService;
