// @ts-check
/* eslint-disable react/react-in-jsx-scope,react/jsx-uses-react,react/prop-types */
import { Link, redirect, useLoaderData, useNavigate } from 'react-router-dom';

import { warrantyApi } from 'store/slices/WarrantyApiSlice';
import { findProduct } from 'services/WarrantyService';
import fallbackImage from 'assets/images/image-fallback.png';
import store from '../../../store';
import { StatusButton } from './shared/StatusButton';
import { TopNavigation } from './TopNavigation';

import style from './ResultPage.module.css';

/** @typedef {import('services/WarrantyService').ProductWarranty} ProductWarranty */

/** @type {import('react-router-dom').RouteObject['loader']} */
export const loader = async ({ request }) => {
  const q = /** @type {string} */ (new URL(request.url).searchParams.get('q'));
  const products = await findProduct(q);

  products.forEach((p) => {
    store.dispatch(warrantyApi.util.upsertQueryData('getProduct', p.code, p));
  });

  if (products.length === 1) {
    return redirect(`/${q}`);
  }

  return products;
};

export function ResultPage() {
  const products = /** @type {ProductWarranty[]} */ (useLoaderData());

  return (
    <>
      <TopNavigation />
      <main className={style.grid}>
        {products.map((product) => (
          <ProductCard key={product.id} data={product} />
        ))}
      </main>
    </>
  );
}

/**
 * @param {Object} props
 * @param {ProductWarranty} props.data
 */
function ProductCard({ data }) {
  const navigate = useNavigate();
  const linkTo = `/${data.code}`;

  return (
    <Link className={style.gridItem} to={linkTo}>
      <img src={data.imageUrl ?? fallbackImage} alt={data.groupName} className={style.image} />
      <div className={style.description}>
        <h3 className={style.productName}>{data.groupName}</h3>
        <span className={style.avoidWrap}>Serial Number:</span>{' '}
        <span className={style.avoidWrap}>{data.serialNumber}</span>
      </div>
      <StatusButton status={data.status} className={style.btn} onClick={() => navigate(linkTo)}>
        Kích hoạt bảo hành
      </StatusButton>
    </Link>
  );
}
